<template>
  <div id="app">
    <search-form />
    <FlashMessage :position="'right top'" />
    <div class="d-flex mb-2">
      <div class="btn-group" role="group">
        <a class="btn btn-primary" :href="batchCreateInvoicePath">{{ $t("invoice.batch_create") }}</a>
        <button type="button" class="btn btn-secondary mr-0 ml-0 pr-0 pl-0" disabled />
        <a v-if="env == 'development'" class="btn btn-primary" :href="demoPath">Demo handsontable (Demo)</a>
        <button v-if="env == 'development'" type="button" class="btn btn-secondary mr-0 ml-0 pr-0 pl-0" disabled />
        <a class="btn btn-primary" :href="listInvoicesPath">{{ $t("invoice.list_invoices") }}</a>
        <button type="button" class="btn btn-secondary mr-0 ml-0 pr-0 pl-0" disabled />
        <a class="btn btn-primary" :href="newInvoicePath">{{ $t("invoice.add_invoice") }}</a>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <div>
        <per-page :per-page="perPage" @update:per-page="(val) => changePerPage(val)" />
      </div>
      <div class="ml-auto">
        <paginator
          :current-page="currentPage"
          :show-paginate="showPaginate"
          :total-pages="totalPages"
          :total-count="totalCount"
          @update:current-page="(page) => loadInvoices(page)"
        />
      </div>
      <br />
    </div>
    <table-invoice :invoices="invoices" />
    <div class="d-flex flex-row-reverse">
      <paginator
        :current-page="currentPage"
        :show-paginate="showPaginate"
        :total-pages="totalPages"
        :total-count="totalCount"
        @update:current-page="(page) => loadInvoices(page)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import SearchForm from "./component_invoices/searchForm.vue"
import TableInvoice from "./component_invoices/tableInvoices.vue"
import PerPage from "./components/perPage.vue"

import { convert_param_url } from "./mixins"

export default {
  components: {
    TableInvoice,
    SearchForm,
    PerPage,
  },
  mixins: [convert_param_url],
  data: function () {
    const env = document.getElementById('env').value
    return {
      batchCreateInvoicePath: `/${this.$i18n.locale}/batch_create/invoices/new?component=NewInvoices`,
      demoPath: `/${this.$i18n.locale}/batch_create/invoices/new?component=HandsonTable`,
      newInvoicePath: `/${this.$i18n.locale}/invoices/new`,
      listInvoicesPath: `/${this.$i18n.locale}/list_invoices`,
      env,
    }
  },
  computed: {
    ...mapState({
      params: (state) => state.invoice.params,
      totalPages: (state) => state.invoice.totalPages,
      totalCount: (state) => state.invoice.totalCount,
      showPaginate: (state) => state.invoice.showPaginate,
      invoices: (state) => state.invoice.invoices,
      perPage: (state) => state.invoice.perPage,
      currentPage: (state) => state.invoice.currentPage,
    }),
    currentPage: {
      get() {
        return this.$store.state.invoice.currentPage
      },
      set(val) {
        this.$store.commit("invoice/setCurrentPage", val)
      },
    },
  },
  created: function () {
    const searchParams = new URLSearchParams(location.search)
    const urlParams = Object.fromEntries(searchParams.entries())
    const payload = this.convert_params(urlParams)
    const params = {
      params: payload || {},
      page: urlParams.page || 1,
      perPage: urlParams.per_page || 30,
    }
    this.$store.dispatch("invoice/submitFormSearch", params)
  },
  methods: {
    loadInvoices: function (current_page) {
      this.$store.dispatch("invoice/submitFormSearch", {
        params: this.params,
        page: current_page,
        perPage: this.perPage,
      })
    },
    changePerPage: function (perPage) {
      const payload = {
        params: this.params,
        page: 1,
        perPage: perPage,
      }
      this.$store.dispatch("invoice/submitFormSearch", payload)
    },
  },
}
</script>
