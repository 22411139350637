<template>
  <tr class="text-center">
    <td>{{ invoice.id }}</td>
    <td>{{ invoice.unit_number }}</td>
    <td>{{ invoice.month }}</td>
    <td>{{ formatNumber(invoice.total_building_unit_price) }}</td>
    <td v-if="electricConsumeShown">
      {{ formatNumber(invoice.end_number_ele - invoice.begin_number_ele) }}
    </td>
    <td v-else>
      {{ formatNumber(invoice.total_electric) }}
    </td>
    <td v-if="waterConsumeShown">
      {{ formatNumber(invoice.end_number_wat - invoice.begin_number_wat) }}
    </td>
    <td v-else>
      {{ formatNumber(invoice.total_water) }}
    </td>
    <td>{{ formatNumber(invoice.total_internet) }}</td>
    <td>{{ formatNumber(invoice.total_parking_fee) }}</td>
    <td>{{ formatNumber(invoice.total_service) }}</td>
    <td>{{ formatNumber(invoice.reduce) }}</td>
    <td>{{ formatNumber(invoice.added) }}</td>
    <td>{{ formatNumber(invoice.total) }}</td>
    <td class="text-center">
      <i
        class="far fa-eye text-primary pointer"
        data-toggle="modal"
        data-target="#detailInvoice"
        @click="getDetailInvoice"
      />
    </td>
    <td class="text-center">
      <i
        class="fas fa-edit text-primary pointer mr-2"
        data-toggle="modal"
        data-target="#editInvoice"
        @click="setCurrentInvoice"
      />
      <i
        :class="['far fa-stop-circle text-danger', invoice.active ? 'pointer' : 'pointer_disable']"
        data-toggle="modal"
        data-target="#deleteInvoice"
        @click="setCurrentInvoice"
      />
    </td>
    <td>
      <a target="_blank" :href="show_invoice" class="btn btn-sm btn-primary mr-2">
        <i class="fas fa-print" />
        <span>{{ $t("invoice.print_x") }}</span>
      </a>
      <a target="_blank" :href="download_invoice" class="btn btn-sm btn-info">
        <i class="fa fa-download" />
        <span>{{ $t("invoice.download") }}</span>
      </a>
    </td>
  </tr>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
const { mapActions } = createNamespacedHelpers("invoice")

import { format_number } from "../mixins"

export default {
  mixins: [format_number],
  props: {
    invoice: {
      type: Object,
      required: true,
    },
    electricConsumeShown: {
      type: Boolean,
      required: true,
    },
    waterConsumeShown: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    show_invoice: function () {
      return `/${this.$i18n.locale}/invoices/${this.invoice.id}`
    },
    download_invoice: function () {
      return `/${this.$i18n.locale}/download/invoices/${this.invoice.id}`
    },
  },
  methods: {
    ...mapActions({
      getInvoiceInfo: "getDetailInvoice",
      setInvoice: "setCurrentInvoice",
      clearErrorMessages: "clearErrorMessages",
    }),
    getDetailInvoice: function () {
      this.getInvoiceInfo(this.invoice.id)
    },
    setCurrentInvoice: function () {
      this.setInvoice(this.invoice)
      this.clearErrorMessages()
    },
  },
}
</script>

<style lang="scss" scoped>
tr > td {
  vertical-align: middle;
}
.pointer_disable {
  pointer-events: none;
  color: gray !important;
}
</style>
