<template>
  <div id="app">
    <FlashMessage :position="'right top'" />
    <NewInvoices />
  </div>
</template>

<script>
import NewInvoices from './component_invoices/newInvoices.vue';

export default {
  components: {
    NewInvoices
  },
  created: function() {
    this.$store.dispatch('invoice/getBuildingUnits');
  }
};
</script>
