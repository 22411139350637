import $ from 'jquery';
import axios from '../axios.js';

export default {
  getOptionBuildingUnits({}, cb) {
    axios.get('/api/statistics/revenues.json', {})
    .then(function(response) {
      cb(response.data);
    }).catch(function(error) {
      console.error(error);
    });
  },
  async loadWithCondition(params, cb) {
    await axios.get('/statistics/revenues.json', {
      params: params,
      paramsSerializer(params) {
        return $.param(params);
      }
    }).then(function(response) {
      cb(response.data);
    }).catch(function(error) {
      console.error(error);
    });
  }
};
