<template>
  <div class="form-group row">
    <label
      v-if="hasLabel"
      :class="['col-form-label', colLabel]"
    >{{ labelText }}</label>
    <div :class="['align-self-center', colInput]">
      <div
        v-for="(val, key) in options"
        :key="key"
        class="form-check form-check-inline"
      >
        <input
          :id="`${inputId}_${key}`"
          v-model="radioVal"
          type="radio"
          class="form-check-input"
          :name="inputId"
          :checked="valueInput == key"
          :value="key"
        >
        <label
          class="form-check-label"
          :for="`${inputId}_${key}`"
          role="button"
        >{{ val }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputRadioButtons',
  props: {
    inputId: {
      type: String,
      required: true
    },
    labelText: {
      type: String,
      default: "Default labelText"
    },
    options: {
      type: Object,
      required: true
    },
    valueInput: {
      type: [String, Number],
      default: "default valueInput"
    },
    colLabel: {
      type: String,
      default: 'col-lg-2'
    },
    colInput: {
      type: String,
      default: 'col-lg-10'
    },
    hasLabel: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:value-input'],
  computed: {
    radioVal: {
      get() {
        return this.valueInput
      },
      set(value) {
        this.$emit('update:value-input', value)
      }
    }
  }
};
</script>
