// Import components
import InputText from '../components/inputText.vue';
import InputSelect2 from '../components/inputSelect2.vue';
import InputDate from '../components/inputDate.vue';
import InputNumber from '../components/inputNumber.vue';
import InputRadioButtons from '../components/inputRadioButtons.vue';
import InputNumberCurrency from '../components/inputNumberCurrency.vue';
import InputToggleSwitch from '../components/inputToggleSwitch.vue';
import InputImageFile from '../components/inputImageFile.vue';
import Paginator from '../components/paginator.vue';
import UploadFile from '../components/uploadFile.vue';
import FieldConfirm from '../components/fieldConfirm.vue';
import FieldOnlyView from '../components/fieldOnlyView.vue';
import FlashMessage from '../components/flash-message.vue';
import PerPage from '../components/perPage.vue';
import LanguageSelector from '../components/language-selector.vue';

// Create plugin
export default {
  install(app) {
    // Register components globally
    app.component('InputText', InputText);
    app.component('InputSelect2', InputSelect2);
    app.component('InputDate', InputDate);
    app.component('InputNumber', InputNumber);
    app.component('InputRadioButtons', InputRadioButtons);
    app.component('InputNumberCurrency', InputNumberCurrency);
    app.component('InputToggleSwitch', InputToggleSwitch);
    app.component('InputImageFile', InputImageFile);
    app.component('Paginator', Paginator);
    app.component('UploadFile', UploadFile);
    app.component('FieldConfirm', FieldConfirm);
    app.component('FieldOnlyView', FieldOnlyView);
    app.component('FlashMessage', FlashMessage);
    app.component('PerPage', PerPage);
    app.component('LanguageSelector', LanguageSelector);
  }
}; 
