<template>
  <tr class="text-center" @dblclick="dbclickShowContract">
    <td>{{ contract.id }}</td>
    <td>{{ contract.holder_name }}</td>
    <td>{{ contract.unit_number }}</td>
    <td>{{ formatNumber(contract.building_unit_price) }}</td>
    <td>{{ formatNumber(contract.deposited_money) }}</td>
    <td>{{ contract.start_date }}</td>
    <td>{{ contract.end_date }}</td>
    <td class="text-center">
      <i
        :class="['fas fa-clone text-primary pointer', contract.active ? 'pointer' : 'pointer_disable']"
        data-toggle="modal"
        data-target="#duplicateAndEditContract"
        @click="getDetailContract"
      />
    </td>
    <td class="text-center">
      <i
        :class="['fas fa-expand-arrows-alt text-primary pointer', contract.active ? 'pointer' : 'pointer_disable']"
        data-toggle="modal"
        data-target="#extendContract"
        @click="getDetailContract"
      />
    </td>
    <td class="text-center">
      <i
        class="far fa-eye text-primary pointer"
        data-toggle="modal"
        data-target="#detailContract"
        @click="getDetailContract"
      />
    </td>
    <td class="text-center">
      <i
        :class="['far fa-stop-circle text-danger', contract.active ? 'pointer' : 'pointer_disable']"
        data-toggle="modal"
        data-target="#terminateContract"
        @click="getDetailContract"
      />
    </td>
    <td class="text-center">
      <div :class="['dot dot-sm ml-auto', statusContract]" />
    </td>
  </tr>
</template>

<script>
import { format_number } from "../mixins"

export default {
  props: {
    contract: {
      type: Object,
      required: true,
    },
  },
  mixins: [format_number],
  computed: {
    statusContract: function () {
      return this.contract.active ? "active" : "inactive"
    },
  },
  methods: {
    getDetailContract: function () {
      this.$store.dispatch("contract/getDetailContract", this.contract)
    },
    dbclickShowContract: function () {
      this.getDetailContract()
      $("#detailContract").modal("toggle")
    },
    handleClickDuplicateAndEditContract: function () {
      console.log("click clone btn")
    },
  },
}
</script>

<style lang="scss" scoped>
.pointer_disable {
  pointer-events: none;
  color: gray !important;
}
</style>
