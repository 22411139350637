import axios from './axios.js';

export default {
  getOrganizations(params, cb) {
    axios
      .get('/api/organizations', {
        params: params,
        paramsSerializer: function (params) {
          return $.param(params);
        }
      })
      .then(function (response) {
        cb(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  }
};
