// Debug script to help identify issues with Vue 3 migration
console.log('Debug script loaded');

// Check Vue version
import { version } from 'vue';
console.log('Vue version:', version);

// Check if store is properly initialized
import { store } from './store';
console.log('Store:', store);

// Check if ComponentsPlugin is properly initialized
import ComponentsPlugin from './plugins/components';
console.log('ComponentsPlugin:', ComponentsPlugin);

// Check if FlashMessagePlugin is properly initialized
import FlashMessagePlugin from './plugins/flash-message-plugin';
console.log('FlashMessagePlugin:', FlashMessagePlugin);

// Check if VueAwesomePaginate is properly initialized
import VueAwesomePaginate from 'vue-awesome-paginate';
console.log('VueAwesomePaginate:', VueAwesomePaginate);

// Import language utilities
import { getCurrentLang } from './utils/language';

// Check if i18n is properly initialized
import { createI18n } from 'vue-i18n';

// Get the locale from the HTML tag
const locale = getCurrentLang();
console.log('HTML lang attribute:', locale);

try {
  const messages = require(`./locales/modules/${locale}`).default;
  console.log('i18n messages:', messages);
  const i18n = createI18n({
    locale: locale,
    allowComposition: true,
    messages: messages
  });
  console.log('i18n:', i18n);
} catch (e) {
  console.error('Error loading i18n:', e);
}

// Export a function to test component initialization
export function testComponentInitialization() {
  try {
    const { createApp } = require('vue');
    const app = createApp({
      template: '<div>Test component</div>'
    });
    console.log('Test app created:', app);
    app.use(store);
    console.log('Store added to app');
    
    // Make the store globally available
    app.config.globalProperties.$store = store;
    console.log('Store made globally available');
    
    app.use(ComponentsPlugin);
    console.log('ComponentsPlugin added to app');
    app.use(FlashMessagePlugin);
    console.log('FlashMessagePlugin added to app');
    app.use(VueAwesomePaginate);
    console.log('VueAwesomePaginate added to app');
    
    // Test flash message and store
    try {
      const instance = app.mount(document.createElement('div'));
      console.log('Test if $flashMessage is available:', instance.$flashMessage);
      console.log('Test if $store is available:', instance.$store);
    } catch (e) {
      console.error('Error testing global properties:', e);
    }
    
    return 'Component initialization test passed';
  } catch (e) {
    console.error('Error in component initialization test:', e);
    return 'Component initialization test failed: ' + e.message;
  }
} 
