import revenues from '../../../api/statistics/revenues';

const state = {
  optionBuildingUnits: [],
  optionReportTypes: [],
  params: {},
  revenues: [],
  currentPage: 1,
  totalPages: 0,
  totalCount: 0,
  showPaginate: false
};

const actions = {
  getOptionBuildingUnits({ commit }) {
    revenues.getOptionBuildingUnits({}, (data) => {
      commit('setOptions', data);
    });
  },
  async submitFormSearch({ commit }, payload) {
    commit('setParams', payload);
    await revenues.loadWithCondition({ q: payload.params, page: payload.page }, (data) => {
      commit('setData', data);
    });
  }
};

const mutations = {
  setOptions(state, data) {
    state.optionBuildingUnits = data.option_building_units;
    state.optionReportTypes = data.option_report_types;
  },
  setData(state, data) {
    state.revenues = data.data;
    state.currentPage = data.meta.page;
    state.totalPages = data.meta.total_pages;
    state.totalCount = data.meta.total_result;
    state.showPaginate = data.meta.total_pages > 1;
  },
  setParams(state, payload) {
    state.params = payload.params;
    state.currentPage = Number(payload.page);
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
