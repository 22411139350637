<template>
  <div id="app" class="d-flex flex-column mx-auto mw-900">
    <FlashMessage :position="'right top'" />
    <InputSelect2
      v-model:selected="invoiceID"
      input-id="invoice_id"
      :place-holder="$t('invoice.select_building_unit')"
      :label-text="$t('invoice.building_unit_no')"
      type-select="single_select"
      :options="collectionBuildingUnits"
      :selected="invoiceID"
    />
    <div ref="invoice" class="p-4 mw-900">
      <div class="text-center d-flex flex-column mb-4">
        <strong>{{ $t("invoice.print_title") }}</strong>
        <span>
          {{ $t("invoice.month") + " " + invoice.month }} -
          {{ $t("invoice.building_unit_no") + " " + invoice.unit_number }}
        </span>
      </div>
      <table class="table table-multi-body table-bordered">
        <thead class="table-header">
          <tr class="text-center">
            <td class="w-5">
              {{ $t("invoice.no") }}
            </td>
            <td class="w-15">
              {{ $t("invoice.service") }}
            </td>
            <td class="w-5">
              {{ $t("invoice.unit") }}
            </td>
            <td class="w-5">
              {{ $t("invoice.qty") }}
            </td>
            <td class="w-10">
              {{ $t("invoice.unit_price") }}
            </td>
            <td class="w-10">
              {{ $t("invoice.into_money") }}
            </td>
            <td class="w-10">
              {{ $t("invoice.note") }}
            </td>
          </tr>
        </thead>
        <tbody class="text-center">
          <tr class="row_building_unit">
            <td>1</td>
            <td class="text-left">
              {{
                `${$t("invoice.building_unit_price")} ${$t("invoice.month").toLowerCase()} ${
                  invoice.hasOwnProperty("month") ? invoice.month.split("/")[0] : null
                }`
              }}
            </td>
            <td>{{ $t("invoice.month") }}</td>
            <td>{{ $t("invoice.num_month") }}</td>
            <td>{{ formatNumber(invoice.building_unit_price) }}</td>
            <td>{{ formatNumber(invoice.total_building_unit_price) }}</td>
            <td>{{ invoiceNotes.building_unit_price }}</td>
          </tr>
          <tr class="row_electric">
            <td>2</td>
            <td class="text-left">
              <span>{{ $t("invoice.ele_price") }}</span
              ><br />
              <span>{{
                $t("invoice.from_to", {
                  begin: invoice.begin_number_ele,
                  end: invoice.end_number_ele,
                })
              }}</span>
            </td>
            <td>{{ invoice.unit_electric }}</td>
            <td>{{ formatNumber(invoice.qty_electric) }}</td>
            <td>{{ formatNumber(invoice.unit_price_electric) }}</td>
            <td>{{ formatNumber(invoice.total_electric) }}</td>
            <td />
          </tr>
          <tr class="row_water">
            <td>3</td>
            <td class="text-left">
              <span>{{ $t("invoice.wat_price") }}</span
              ><br />
              <span>{{
                $t("invoice.from_to", {
                  begin: invoice.begin_number_wat,
                  end: invoice.end_number_wat,
                })
              }}</span>
            </td>
            <td>{{ invoice.unit_water }}</td>
            <td>{{ formatNumber(invoice.qty_water) }}</td>
            <td>{{ formatNumber(invoice.unit_price_water) }}</td>
            <td>{{ formatNumber(invoice.total_water) }}</td>
            <td />
          </tr>
          <tr class="row_parking_fee">
            <td>4</td>
            <td class="text-left">
              {{ $t("invoice.paf_price") }}
            </td>
            <td>{{ invoice.unit_parking_fee }}</td>
            <td>{{ formatNumber(invoice.qty_parking_fee) }}</td>
            <td>{{ formatNumber(invoice.unit_price_parking_fee) }}</td>
            <td>{{ formatNumber(invoice.total_parking_fee) }}</td>
            <td />
          </tr>
          <tr class="row_internet">
            <td>5</td>
            <td class="text-left">
              {{ $t("invoice.int_price") }}
            </td>
            <td>{{ invoice.unit_internet }}</td>
            <td>{{ formatNumber(invoice.qty_internet) }}</td>
            <td>{{ formatNumber(invoice.unit_price_internet) }}</td>
            <td>{{ formatNumber(invoice.total_internet) }}</td>
            <td />
          </tr>
          <tr class="row_service">
            <td>6</td>
            <td class="text-left">
              {{ $t("invoice.ser_price") }}
            </td>
            <td>{{ invoice.unit_service }}</td>
            <td>{{ formatNumber(invoice.qty_service) }}</td>
            <td>{{ formatNumber(invoice.unit_price_service) }}</td>
            <td>{{ formatNumber(invoice.total_service) }}</td>
            <td />
          </tr>
          <tr class="row_total_1">
            <td />
            <td class="font-weight-bold">
              {{ $t("invoice.total_1") }}
            </td>
            <td />
            <td />
            <td />
            <td>{{ formatNumber(invoice.total_cost) }}</td>
            <td />
          </tr>
          <tr class="row_reduce">
            <td />
            <td colspan="3" class="text-left">
              {{ $t("invoice.reduce_or_increase") }}
            </td>
            <td />
            <td />
            <td />
          </tr>
          <tr class="row_reduce_1">
            <td>1</td>
            <td class="text-left">
              {{ $t("invoice.reduce_1") }}
            </td>
            <td />
            <td />
            <td />
            <td>{{ formatNumber(invoice.reduce) }}</td>
            <td>{{ invoiceNotes.reduced }}</td>
          </tr>
          <tr class="row_reduce_2">
            <td>2</td>
            <td class="text-left">
              {{ $t("invoice.added") }}
            </td>
            <td />
            <td />
            <td />
            <td>{{ formatNumber(invoice.added) }}</td>
            <td>{{ invoiceNotes.added }}</td>
          </tr>
          <tr class="row_reduce_3">
            <td>3</td>
            <td class="text-left" />
            <td />
            <td />
            <td />
            <td />
            <td />
          </tr>
          <tr class="row_total_2">
            <td />
            <td class="font-weight-bold">
              {{ $t("invoice.total_2") }}
            </td>
            <td />
            <td />
            <td />
            <td>{{ formatNumber(invoice.total_reduce) }}</td>
            <td />
          </tr>
          <tr class="row_total">
            <td />
            <td colspan="2" class="font-weight-bold">
              {{ $t("invoice.print_total") }}
            </td>
            <td />
            <td />
            <td>{{ formatNumber(invoice.total_revenue) }}</td>
            <td />
          </tr>
        </tbody>
      </table>
      <br />
      <div class="d-flex flex-column">
        <span>{{ $t("invoice.payment_deadline") }}</span>
        <span>{{ $t("invoice.transfer_infor") }}</span>
        <span class="text-indent">
          {{
            $t("invoice.account_name", {
              text: invoice.sub_merchant ? invoice.sub_merchant.bank_account_holder : null,
            })
          }}
        </span>
        <span class="text-indent">{{ $t("invoice.account_number", subMerchantInfo()) }}</span>
        <span class="text-indent">{{ $t("invoice.transfer_content") }}</span>
        <span class="text-indent-a">{{ $t("invoice.content") }}</span
        ><br />
        <span class="ml-auto">{{ $t("invoice.date_time") }}</span
        ><br />
        <div class="col-md-12 row mb-5">
          <span class="col-md-6 font-weight-bold text-center">{{ $t("invoice.payer") }}</span>
          <span class="col-md-6 font-weight-bold text-center">{{ $t("invoice.receiptor") }}</span>
        </div>
      </div>
    </div>
    <div class="footer mb-5">
      <button class="btn btn-primary" type="button" @click="copyInvoice">
        {{ $t("invoice.export_image") }}
      </button>
      <button class="btn btn-danger" type="button" @click="printInvoice">
        {{ $t("invoice.print") }}
      </button>
    </div>
    <!-- Modal -->
    <div id="imgInvoice" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog mw-900" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div id="divImgInvoice" class="modal-body text-center" />
            <button type="button" class="btn btn-secondary" @click="copyImageToClipboard">
              {{ $t("invoice.copy_image") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "./api/axios.js"
import $ from "jquery"
import { createNamespacedHelpers } from "vuex"
const { mapState, mapActions } = createNamespacedHelpers("invoice")

import { show_flash_mixins, format_number } from "./mixins"

export default {
  mixins: [show_flash_mixins, format_number],
  data: function () {
    const invoiceID = document.getElementById("invoiceID").value

    return {
      flashMsg: this.$t("invoice.copy_success"),
      isExportingImage: false,
      invoiceID,
      isGeneratingImage: false,
    }
  },
  computed: {
    ...mapState({
      invoice: "currentInvoice",
      invoices: (state) => state.invoices,
    }),
    invoiceNotes: function () {
      if (!this.invoice.invoice_notes) return {}

      return Object.fromEntries(this.invoice.invoice_notes.map((note) => [note.field_for, note.content]))
    },
    collectionBuildingUnits: function () {
      if (this.invoices.length === 0) return {}

      return Object.fromEntries(this.invoices.map((invoice) => [invoice.id, invoice.unit_number]))
    },
  },
  watch: {
    invoiceID: function (val) {
      if (!val) return

      this.fetchInvoice(val)
    },
  },
  created: async function () {
    await this.fetchInvoice(this.invoiceID)
    this.submitForm()
  },
  methods: {
    ...mapActions(["setCurrentInvoice", "submitFormSearch"]),
    subMerchantInfo: function () {
      if (!this.invoice.sub_merchant) return new Array(3)

      return [
        this.invoice.sub_merchant.bank_account_number,
        this.invoice.sub_merchant.bank_name,
        this.invoice.sub_merchant.bank_branch,
      ]
    },
    printInvoice: function () {
      const prtHtml = this.$refs.invoice.innerHTML

      let stylesHtml = ""
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML
      }

      const WinPrint = window.open("", "", "scrollbars=0,status=0,fullscreen=0")
      WinPrint.document.write(`<!DOCTYPE html>
                                <html>
                                  <head>
                                    ${stylesHtml}
                                  </head>
                                  <body>
                                    ${prtHtml}
                                  </body>
                                </html>`)

      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
    },
    copyInvoice() {
      if (this.isGeneratingImage) {
        this.flashMsg = this.$t("invoice.generate_image_in_progress")
        this.show_flash(true)
      } else {
        this.isGeneratingImage = true
        const self = this
        const node = self.$refs.invoice
        const divImgInvoice = document.getElementById("divImgInvoice")
        divImgInvoice.innerHTML = null
        window.domtoimage
          .toPng(node, { bgcolor: "white" })
          .then(function (dataUrl) {
            const img = new Image()
            img.src = dataUrl
            img.style = "width: 100%;"
            divImgInvoice.appendChild(img)
            $("#imgInvoice").modal("toggle")
          })
          .catch(function (error) {
            console.error(error)
          })
          .finally(function () {
            self.isGeneratingImage = false
          })
      }
    },
    copyImageToClipboard() {
      const self = this
      const imageElem = document.getElementById("divImgInvoice").getElementsByTagName("img")[0]
      fetch(imageElem.getAttribute("src"))
        .then((res) => res.blob())
        .then((blob) => {
          const data = [new ClipboardItem({ [blob.type]: blob })]
          return navigator.clipboard.write(data)
        })
        .then(() => {
          self.flashMsg = this.$t("invoice.copy_success")
          self.show_flash(true)
        })
        .catch((error) => console.error(error))
    },
    submitForm() {
      const [month_export, year_export] = this.invoice.month.split("/")
      const params = {
        month_export,
        year_export,
      }
      const payload = {
        params,
        page: 1,
        perPage: 1000,
        preventReload: true,
      }
      this.submitFormSearch(payload)
    },
    async fetchInvoice(invoiceId) {
      const self = this
      return await axios
        .get(`/invoices/${invoiceId}.json`, {})
        .then(function (response) {
          self.setCurrentInvoice(response.data.invoice)
        })
        .catch(function (error) {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.table-bordered {
  th,
  td {
    padding: 0.1rem;
    border: 1px solid #000 !important;
  }
}

.text-indent {
  text-indent: 15px;
}

.text-indent-a {
  text-indent: 30px;
}

.mw-900 {
  max-width: 900px !important;
}

.footer {
  display: flex;
  justify-content: space-between;
}
</style>
