<template>
  <div id="app">
    <FlashMessage :position="'right top'" />
    <component
      :is="component"
    />
  </div>
</template>

<script>
import NewInvoices from './component_invoices/batch_create/newInvoices.vue';
import HandsonTable from './component_invoices/batch_create/handson-table.vue';

export default {
  components: {
    NewInvoices,
    HandsonTable
  },
  props: {
    component: {
      type: String,
      default: 'NewInvoices'
    }
  }
};
</script>
