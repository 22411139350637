<template>
  <tr class="text-center">
    <td class="font-weight-bold">
      {{ contractHistory.id }}
    </td>
    <td>{{ toString(contractHistory.date_changed) }}</td>
    <td>{{ contractHistory.unit_number }}</td>
    <td role="button" @click="detailContract(contractHistory.contract_id)">
      {{ $t(`contract_history.code.${contractHistory.code}`) }}
    </td>
    <td role="button" @click="detailUser(contractHistory.holder_id)">
      {{ contractHistory.holder_name }}
    </td>
    <td>{{ contractHistory.admin_name }}</td>
  </tr>
</template>

<script>
import { format_string } from "../../mixins"

export default {
  props: {
    contractHistory: {
      type: Object,
      default: () => {},
    },
  },
  mixins: [format_string],
  methods: {
    detailContract: function (val) {
      const self = this
      this.$store.dispatch("contract/getDetailContract", { id: val }).then(() => {
        self.$emit("click_contract")
      })
    },
    detailUser: function (val) {
      const self = this
      this.$store.dispatch("user/loadDetailUser", { id: val }).then(() => {
        self.$emit("click_user")
      })
    },
  },
}
</script>
