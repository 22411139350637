<template>
  <div id="app">
    <flash-message :position="'right top'" />
    <div v-if="isIndex" class="table-contract">
      <search-form />
      <div class="d-flex">
        <div :class="['btn-new-contract', { 'mb-2': !showPaginate }]">
          <button class="btn btn-primary" @click="createForm">
            {{ $t("contract.new_contract") }}
          </button>
        </div>
        <div class="ml-auto">
          <paginator
            :current-page="currentPage"
            :show-paginate="showPaginate"
            :total-pages="totalPages"
            :total-count="totalCount"
            @update:current-page="(page) => loadContracts(page)"
          />
        </div>
      </div>
      <table-contract :contracts="contracts" />
      <div class="d-flex flex-row-reverse">
        <paginator
          :current-page="currentPage"
          :show-paginate="showPaginate"
          :total-pages="totalPages"
          :total-count="totalCount"
          @update:current-page="(page) => loadContracts(page)"
        />
      </div>
    </div>
    <div v-else class="new-contract">
      <div class="d-flex">
        <new-contract />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import TableContract from "./component_contracts/tableContracts.vue"
import NewContract from "./component_contracts/newContract.vue"
import SearchForm from "./component_contracts/searchForm.vue"
import { convert_param_url } from "./mixins"

export default {
  components: {
    TableContract,
    NewContract,
    SearchForm,
  },
  mixins: [convert_param_url],
  computed: {
    ...mapState({
      totalPages: (state) => state.contract.totalPages,
      totalCount: (state) => state.contract.totalCount,
      showPaginate: (state) => state.contract.showPaginate,
      contracts: (state) => state.contract.contracts,
      isIndex: (state) => state.contract.isIndex,
      params: (state) => state.contract.params,
    }),
    currentPage: {
      get() {
        return this.$store.state.contract.currentPage
      },
      set(val) {
        this.$store.commit("contract/setCurrentPage", val)
      },
    },
  },
  created: function () {
    const searchParams = new URLSearchParams(location.search)
    const urlParams = Object.fromEntries(searchParams.entries())
    const payload = this.convert_params(urlParams)
    const params = {
      params: payload || {},
      page: urlParams.page || 1,
    }
    this.$store.dispatch("contract/submitFormSearch", params)
    this.$store.dispatch("contract/getBuildingUnits")
    this.$store.dispatch("contract/getUsers")
  },
  methods: {
    loadContracts: function (current_page) {
      this.$store.dispatch("contract/submitFormSearch", { params: this.params, page: current_page })
    },
    createForm: function () {
      this.$store.dispatch("contract/setIsIndex", false)
    },
  },
}
</script>
