import { createStore } from "vuex"
import user from "./modules/user"
import floor from "./modules/floor"
import contract from "./modules/contract"
import invoice from "./modules/invoice"
import revenue from "./modules/statistics/revenue"
import contract_history from "./modules/statistics/contract_history"
import organization from "./modules/organization"

export const store = createStore({
  modules: {
    user,
    floor,
    contract,
    invoice,
    revenue,
    contract_history,
    organization,
  },
})
