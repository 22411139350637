import axios from "axios";
import { getCurrentLang } from "../utils/language";

// Ensure NProgress is available
const NProgress = window.NProgress || { start: () => {}, done: () => {} };

axios.interceptors.request.use(
  function(config) {
    NProgress.start();
    const locale = getCurrentLang();
    config.url = `/${locale}${config.url}`;
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function(response) {
    NProgress.done();
    return response;
  },
  function(error) {
    NProgress.done();
    return Promise.reject(error);
  }
);

export default axios;
