<template>
  <div class="form-group row">
    <label :for="inputId" :class="['col-form-label', colLabel]">{{ labelText }}</label>
    <div :class="[colInput]">
      <input
        :id="inputId"
        v-model="inputVal"
        type="text"
        class="form-control"
        autocomplete="off"
        :disabled="disabled"
        :inputmode="inputMode"
      />
      <span v-if="eMsg" class="text-danger">{{ eMsg }}</span>
    </div>
  </div>
</template>

<script>
import { format_number } from "../mixins"

export default {
  name: "InputNumberCurrency",
  mixins: [format_number],
  props: {
    inputId: {
      type: String,
      required: true,
    },
    labelText: {
      type: String,
      required: true,
    },
    valueInput: {
      type: [Number, String],
      default: 0,
    },
    eMsg: {
      type: String,
      default: "",
    },
    colLabel: {
      type: String,
      default: "col-lg-2",
    },
    colInput: {
      type: String,
      default: "col-lg-10",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputMode: {
      type: String,
      default: "text",
    },
  },
  emits: ["update:value-input"],
  data() {
    return {}
  },
  computed: {
    inputVal: {
      get() {
        return this.formatNumber(this.valueInput)
      },
      set(value) {
        this.$emit("update:value-input", numeral(value).value())
      },
    },
  },
}
</script>
