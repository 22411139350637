<template>
  <div
    id="editInvoice"
    class="modal fade"
    role="dialog"
    aria-modal="true"
    aria-hidden="true"
    data-backdrop="true"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ $t("invoice.edit_invoice") }} {{ $t("invoice.building_unit") }}
            <strong>{{ currentInvoice.unit_number }}</strong>
            {{ $t("invoice.month") }}
            <strong>{{ currentInvoice.month }}</strong>
          </h5>
        </div>
        <div v-if="invoiceExist">
          <div v-show="isRegistration">
            <div class="modal-body">
              <FieldConfirm
                :label-text="$t('invoice.building_unit_price')"
                :value="formatNumber(currentInvoice.building_unit_price)"
                col-label="col-md-3"
                col-input="col-md-9"
              />
              <InputText
                v-show="isShowNote"
                v-model:value-input="localInvoiceNotes.building_unit_price"
                input-id="note_building_unit_price"
                :label-text="$t('invoice.note')"
                col-label="col-md-3"
                col-input="col-md-9"
              />
              <InputText
                v-model:value-input="localInvoice.day_lived"
                input-id="day_used_per_month"
                :label-text="$t('invoice.day_used_per_month')"
                :return-number="true"
                :e-msg="deliverErrors('day_used_per_month')"
                col-label="col-md-3"
                col-input="col-md-9"
              />
              <InputText
                v-model:value-input="localInvoice.begin_number_ele"
                input-id="electric_start"
                :label-text="$t('invoice.electric_start')"
                :return-number="true"
                :e-msg="deliverErrors('electric_start')"
                col-label="col-md-3"
                col-input="col-md-9"
              />
              <InputText
                v-model:value-input="localInvoice.end_number_ele"
                input-id="electric_end"
                :label-text="$t('invoice.electric_end')"
                :return-number="true"
                :e-msg="deliverErrors('electric_end')"
                col-label="col-md-3"
                col-input="col-md-9"
              />
              <InputText
                v-model:value-input="localInvoice.begin_number_wat"
                input-id="water_start"
                :label-text="$t('invoice.water_start')"
                :return-number="true"
                :e-msg="deliverErrors('water_start')"
                col-label="col-md-3"
                col-input="col-md-9"
              />
              <InputText
                v-model:value-input="localInvoice.end_number_wat"
                input-id="water_end"
                :label-text="$t('invoice.water_end')"
                :return-number="true"
                :e-msg="deliverErrors('water_end')"
                col-label="col-md-3"
                col-input="col-md-9"
              />
              <InputNumberCurrency
                v-model:value-input="localInvoice.unit_price_internet"
                input-id="unit_price_internet"
                :label-text="$t('invoice.unit_price_internet')"
                :e-msg="deliverErrors('unit_price_internet')"
                col-label="col-md-3"
                col-input="col-md-9"
              />
              <InputNumberCurrency
                v-model:value-input="localInvoice.unit_price_parking_fee"
                input-id="unit_price_parking_fee"
                :label-text="$t('invoice.unit_price_parking_fee')"
                col-label="col-md-3"
                col-input="col-md-9"
              />
              <InputText
                v-model:value-input="localInvoice.qty_parking_fee"
                input-id="quantity_parking"
                :label-text="$t('invoice.quantity_parking')"
                :return-number="true"
                :e-msg="deliverErrors('quantity_parking')"
                col-label="col-md-3"
                col-input="col-md-9"
              />
              <InputNumberCurrency
                v-model:value-input="localInvoice.unit_price_service"
                input-id="unit_price_service_fee"
                :label-text="$t('invoice.unit_price_service_fee')"
                :e-msg="deliverErrors('unit_price_service_fee')"
                col-label="col-md-3"
                col-input="col-md-9"
              />
              <InputNumberCurrency
                v-model:value-input="localInvoice.reduce"
                input-id="reduce"
                :label-text="$t('invoice.reduce')"
                :e-msg="deliverErrors('reduce')"
                col-label="col-md-3"
                col-input="col-md-9"
              />
              <InputText
                v-show="isShowNote"
                v-model:value-input="localInvoiceNotes.reduced"
                input-id="note_reduced"
                :label-text="$t('invoice.note')"
                col-label="col-md-3"
                col-input="col-md-9"
              />
              <InputNumberCurrency
                v-model:value-input="localInvoice.added"
                input-id="added"
                :label-text="$t('invoice.added')"
                :e-msg="deliverErrors('added')"
                col-label="col-md-3"
                col-input="col-md-9"
              />
              <InputText
                v-show="isShowNote"
                v-model:value-input="localInvoiceNotes.added"
                input-id="note_added"
                :label-text="$t('invoice.note')"
                col-label="col-md-3"
                col-input="col-md-9"
              />
              <InputNumberCurrency
                v-model:value-input="localInvoice.total"
                :input-id="'total'"
                :label-text="$t('invoice.total')"
                :disabled="true"
                col-label="col-lg-3"
                col-input="col-lg-9"
              />
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-info mr-auto" @click="toggleNoteField">
                {{ $t("invoice.note") }}
              </button>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">
                {{ $t("invoice.close") }}
              </button>
              <button type="button" class="btn btn-primary" @click="submitConfirm">
                {{ $t("invoice.submit") }}
              </button>
            </div>
          </div>
          <div v-show="isConfirm">
            <div class="modal-body">
              <FieldConfirm
                :label-text="$t('invoice.building_unit_price')"
                :value="formatNumber(currentInvoice.building_unit_price)"
                col-label="col-md-3"
                col-input="col-md-9"
              />
              <FieldConfirm
                :label-text="$t('invoice.note')"
                :value="localInvoiceNotes.building_unit_price"
                col-label="col-md-3"
                col-input="col-md-9"
              />
              <FieldConfirm
                :label-text="$t('invoice.day_used_per_month')"
                :value="localInvoice.day_lived"
                col-label="col-md-3"
                col-input="col-md-9"
              />
              <FieldConfirm
                :label-text="$t('invoice.electric_start')"
                :value="formatNumber(localInvoice.begin_number_ele)"
                col-label="col-md-3"
                col-input="col-md-9"
              />
              <FieldConfirm
                :label-text="$t('invoice.electric_end')"
                :value="formatNumber(localInvoice.end_number_ele)"
                col-label="col-md-3"
                col-input="col-md-9"
              />
              <FieldConfirm
                :label-text="$t('invoice.water_start')"
                :value="formatNumber(localInvoice.begin_number_wat)"
                col-label="col-md-3"
                col-input="col-md-9"
              />
              <FieldConfirm
                :label-text="$t('invoice.water_end')"
                :value="formatNumber(localInvoice.end_number_wat)"
                col-label="col-md-3"
                col-input="col-md-9"
              />
              <FieldConfirm
                :label-text="$t('invoice.unit_price_internet')"
                :value="formatNumber(localInvoice.unit_price_internet)"
                col-label="col-md-3"
                col-input="col-md-9"
              />
              <FieldConfirm
                :label-text="$t('invoice.unit_price_parking_fee')"
                :value="formatNumber(localInvoice.unit_price_parking_fee)"
                col-label="col-md-3"
                col-input="col-md-9"
              />
              <FieldConfirm
                :label-text="$t('invoice.quantity_parking')"
                :value="formatNumber(localInvoice.qty_parking_fee)"
                col-label="col-md-3"
                col-input="col-md-9"
              />
              <FieldConfirm
                :label-text="$t('invoice.unit_price_service_fee')"
                :value="formatNumber(localInvoice.unit_price_service)"
                col-label="col-md-3"
                col-input="col-md-9"
              />
              <FieldConfirm
                :label-text="$t('invoice.reduce')"
                :value="formatNumber(localInvoice.reduce)"
                col-label="col-md-3"
                col-input="col-md-9"
              />
              <FieldConfirm
                :label-text="$t('invoice.note')"
                :value="localInvoiceNotes.reduced"
                col-label="col-md-3"
                col-input="col-md-9"
              />
              <FieldConfirm
                :label-text="$t('invoice.added')"
                :value="formatNumber(localInvoice.added)"
                col-label="col-md-3"
                col-input="col-md-9"
              />
              <FieldConfirm
                :label-text="$t('invoice.note')"
                :value="localInvoiceNotes.added"
                col-label="col-md-3"
                col-input="col-md-9"
              />
              <FieldConfirm
                :label-text="$t('invoice.total')"
                :value="formatNumber(localInvoice.total)"
                col-label="col-md-3"
                col-input="col-md-9"
              />
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="backToRegistration">
                {{ $t("invoice.back") }}
              </button>
              <button type="button" class="btn btn-primary" data-dismiss="modal" @click="submitForm">
                {{ $t("invoice.save_change") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery"
import { createNamespacedHelpers } from "vuex"
import { format_number, show_flash_mixins } from "../mixins"

const { mapState, mapActions } = createNamespacedHelpers("invoice")

export default {
  mixins: [format_number, show_flash_mixins],
  data: function () {
    return {
      isRegistration: true,
      isConfirm: false,
      localInvoice: null,
      localInvoiceNotes: {},
      isShowNote: false,
    }
  },
  computed: {
    ...mapState({
      currentInvoice: (state) => state.currentInvoice,
      isValid: (state) => state.isValid,
      errorMessages: (state) => state.errorMessages,
      editInvoice: (state) => state.editInvoice,
    }),
    eleTotal: function () {
      const electricQuantity = this.localInvoice.end_number_ele - this.localInvoice.begin_number_ele
      return electricQuantity > 0 ? electricQuantity * this.localInvoice.unit_price_electric : 0
    },
    watTotal: function () {
      const waterQuantity = this.localInvoice.end_number_wat - this.localInvoice.begin_number_wat
      return waterQuantity > 0 ? waterQuantity * this.localInvoice.unit_price_water : 0
    },
    totalDayInMonth: function () {
      return new Date(this.localInvoice.month.split("/")[1], this.localInvoice.month.split("/")[0], 0).getDate()
    },
    intTotal: function () {
      return (this.localInvoice.unit_price_internet * this.localInvoice.day_lived) / this.totalDayInMonth
    },
    pafTotal: function () {
      return (
        (this.localInvoice.unit_price_parking_fee * this.localInvoice.day_lived * this.localInvoice.qty_parking_fee) /
        this.totalDayInMonth
      )
    },
    serTotal: function () {
      return (this.localInvoice.unit_price_service * this.localInvoice.day_lived) / this.totalDayInMonth
    },
    buildingUnitPriceTotal: function () {
      return (this.localInvoice.building_unit_price * this.localInvoice.day_lived) / this.totalDayInMonth
    },
    invoiceExist: function () {
      return Object.keys(this.currentInvoice).length !== 0
    },
  },
  watch: {
    currentInvoice: {
      handler(val) {
        this.localInvoice = { ...val }
        const tempInvoiceNotes = {}
        this.localInvoice.invoice_notes.forEach(function (note) {
          if (tempInvoiceNotes[note.field_for]) return

          tempInvoiceNotes[note.field_for] = note.content
        })
        this.localInvoiceNotes = { ...tempInvoiceNotes }
      },
      deep: true,
    },
    localInvoice: {
      handler(val) {
        this.calculateTotal()
      },
      deep: true,
    },
  },
  mounted: function () {
    const self = this
    $("#editInvoice").on("hidden.bs.modal", function () {
      self.isShowNote = false
    })
  },
  methods: {
    ...mapActions(["updateInvoice"]),
    submitConfirm: async function () {
      const params = {
        ...this.payload(),
        step: "confirmation",
      }
      await this.updateInvoice(params)
      if (!this.isValid) {
        this.flashMsg = JSON.stringify(this.errorMessages)
        this.show_flash(this.isValid)
        return
      }

      this.isRegistration = false
      this.isConfirm = true
    },
    submitForm: async function () {
      const params = {
        ...this.payload(),
        step: "done",
      }
      await this.updateInvoice(params)
      this.isRegistration = true
      this.isConfirm = false
      this.show_flash(this.isValid)
    },
    backToRegistration: function () {
      this.isConfirm = false
      this.isRegistration = true
    },
    deliverErrors: function (attribute) {
      if (!this.errorMessages[0]) return

      return this.errorMessages[0][attribute]
    },
    calculateTotal: function () {
      this.localInvoice.total =
        this.buildingUnitPriceTotal +
        this.eleTotal +
        this.watTotal +
        this.intTotal +
        this.pafTotal +
        this.serTotal +
        this.localInvoice.added -
        this.localInvoice.reduce
    },
    payload: function () {
      return {
        id: this.localInvoice.id,
        building_unit_id: this.localInvoice.building_unit_id,
        day_used_per_month: this.localInvoice.day_lived,
        electric_start: this.localInvoice.begin_number_ele,
        electric_end: this.localInvoice.end_number_ele,
        water_start: this.localInvoice.begin_number_wat,
        water_end: this.localInvoice.end_number_wat,
        quantity_parking: this.localInvoice.qty_parking_fee,
        unit_price_internet: this.localInvoice.unit_price_internet,
        unit_price_parking_fee: this.localInvoice.unit_price_parking_fee,
        unit_price_service_fee: this.localInvoice.unit_price_service,
        electric_unit_price: this.localInvoice.electric_unit_price,
        water_unit_price: this.localInvoice.water_unit_price,
        reduce: this.localInvoice.reduce,
        added: this.localInvoice.added,
        total: this.localInvoice.total,
        invoice_notes: this.formatedInvoiceNote(),
      }
    },
    formatedInvoiceNote: function () {
      return Object.keys(this.localInvoiceNotes).map((key) => {
        return {
          field_for: key,
          content: this.localInvoiceNotes[key],
        }
      })
    },
    toggleNoteField: function () {
      this.isShowNote = !this.isShowNote
    },
  },
}
</script>
