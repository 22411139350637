<template>
  <div class="language-selector">
    <select
      v-model="selectedLanguage"
      @change="changeLanguage"
      class="form-control"
    >
      <option
        v-for="lang in availableLanguages"
        :key="lang.code"
        :value="lang.code"
      >
        {{ lang.name }}
      </option>
    </select>
  </div>
</template>

<script>
import { setHtmlLang, getCurrentLang } from "../utils/language";

export default {
  name: "LanguageSelector",
  props: {
    availableLanguages: {
      type: Array,
      default: () => [
        { code: "en", name: "English" },
        { code: "ja", name: "日本語" },
        { code: "vi", name: "Tiếng Việt" },
      ],
    },
  },
  data() {
    return {
      selectedLanguage: getCurrentLang(),
    };
  },
  methods: {
    changeLanguage() {
      // Set the language attribute on the HTML tag
      setHtmlLang(this.selectedLanguage);

      // Redirect to the same page with the new locale
      const currentPath = window.location.pathname;
      const newPath = currentPath.replace(
        /^\/[a-z]{2}\//,
        `/${this.selectedLanguage}/`
      );
      const queryParams = window.location.search;

      // If the path doesn't start with a locale, add it
      if (newPath === currentPath) {
        window.location.href = `/${this.selectedLanguage}${currentPath}${queryParams}`;
      } else {
        window.location.href = `${newPath}${queryParams}`;
      }
    },
  },
};
</script>

<style scoped>
.language-selector {
  display: inline-block;
  margin: 0 10px;
}
</style> 
