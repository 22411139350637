<template>
  <div class="form-group row">
    <div class="col-lg-2">
      <label class="switch">
        <input  
          v-model="checked"
          type="checkbox"
          :id="inputId"
        >
        <span class="slider round" />
      </label>
    </div>
    <label
      :for="inputId"
      class="col-lg-2 col-form-label"
    >{{ labelText }}</label>
  </div>
</template>

<script>
export default {
  name: 'InputToggleSwitch',
  props: {
    inputId: {
      type: String,
      required: true
    },
    labelText: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  emits: ['checked'],
  data() {
    return {
      checked: this.value
    };
  },
  watch: {
    checked(val) {
      this.$emit('checked', val);
    },
    value(val) {
      this.checked = val;
    }
  }
};
</script>
