import $ from "jquery";
import organization from '../../api/organizations';

const state = {
  params: {},
  organizations: [],
  showPaginate: false,
  totalPages: 0,
  totalCount: 0,
  currentPage: 0
};

const actions = {
  submitFormSearch({ commit }, payload) {
    commit('setParams', payload);
    const params = { q: payload.params, page: payload.page };
    const path = `/organizations?${$.param(params)}`;
    history.pushState('', '', path);
    organization.getOrganizations(params, (data) => {
      commit('setOrganizations', data);
    });
  }
};

const mutations = {
  setParams(state, payload) {
    state.params = payload.params;
    state.currentPage = Number(payload.page);
  },
  setCurrentPage(state, page) {
    state.currentPage = Number(page);
  },
  setOrganizations(state, data) {
    state.currentPage = Number(data.meta.page);
    state.totalPages = data.meta.total_pages;
    state.totalCount = data.meta.total_result;
    state.showPaginate = data.meta.total_pages > 1;
    state.organizations = data.data;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
