<template>
  <div class="d-inline-block">
    {{ $t("paginate.result") }}
    <span class="font-weight-bold text-danger">
      {{ totalCount }}
    </span>
    <nav class="d-inline-block ml-4">
      <vue-awesome-paginate
        v-show="showPaginate"
        v-model="pageNo"
        :total-items="totalPages"
        :items-per-page="1"
        :max-pages-shown="5"
      />
    </nav>
  </div>
</template>
<script>
export default {
  name: "Paginator",
  props: {
    showPaginate: {
      type: Boolean,
      default: false,
    },
    currentPage: {
      type: Number,
      default: 0,
    },
    totalPages: {
      type: Number,
      default: 0,
    },
    totalCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    pageNo: {
      get() {
        return this.currentPage
      },
      set(value) {
        this.$emit("update:current-page", value)
      },
    },
  },
}
</script>

<style>
.pagination-container {
  display: flex;
  column-gap: 10px;
}
.paginate-buttons {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(217, 217, 217);
  color: black;
}
.paginate-buttons:hover {
  background-color: #d8d8d8;
}
.active-page {
  background-color: #3498db;
  border: 1px solid #3498db;
  color: white;
}
.active-page:hover {
  background-color: #2988c8;
}
</style>
