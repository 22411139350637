<template>
  <div class="form-group row">
    <label
      :for="inputId"
      :class="['col-form-label', colLabel, required ? 'required' : null]"
    >{{ labelText }}</label>
    <div :class="[colInput]">
      <input
        :id="inputId"
        v-model.trim="currentValue"
        :type="typeInput"
        :inputmode="inputMode"
        class="form-control"
        :disabled="disabled"
        :placeholder="placeholder"
      >
      <span
        v-if="eMsg"
        class="text-danger"
      >{{ eMsg }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputText',
  props: {
    typeInput: {
      type: String,
      default: "text"
    },
    inputId: {
      type: String,
      required: true
    },
    labelText: {
      type: String,
      required: true
    },
    valueInput: {
      type: [Number, String],
      default(rawProps) {
        if (["string", "undefined"].includes(typeof(rawProps))) {
          return "";
        } else {
          return 0;
        }
      }
    },
    eMsg: {
      type: String,
      default: ""
    },
    colLabel: {
      type: String,
      default: "col-lg-2"
    },
    colInput: {
      type: String,
      default: "col-lg-10"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    returnNumber: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    inputMode: {
      type: String,
      default: "text"
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:value-input'],
  computed: {
    currentValue: {
      get() {
        return this.valueInput;
      },
      set(value) {
        const emitValue = this.returnNumber ? this.parseNumber(value) : value;
        this.$emit("update:value-input", emitValue);
      }
    }
  },
  methods: {
    parseNumber(str) {
      if (typeof str === "number") return str;

      return Number(str.replace(/[^0-9.-]+/g, ""));
    }
  }
};
</script>

<style lang="scss" scoped>
  label.required::after {
    content: "*";
    display: inline-block;
  } 
</style>
