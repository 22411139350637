export default {
  methods: {
    show_flash: function(isValid) {
      const self = this;
      if (isValid) {
        this.$flashMessage.show({
          type: 'success',
          time: 3000,
          message: self.flashMsg || self.$t('flash.success')
        });
      } else {
        this.$flashMessage.show({
          type: 'error',
          time: 3000,
          message: self.flashMsg || self.$t('flash.unsuccess')
        });
      }
    }
  }
};
