<template>
  <form id="new-invoices" @submit.prevent="checkForm">
    <button v-if="isValid" class="btn btn-primary float-right mb-2">
      {{ $t("invoice.submit_form") }}
    </button>
    <button v-else class="btn btn-primary float-right mb-2">
      {{ $t("invoice.check_form") }}
    </button>
    <InputDate
      v-model:value-input="month"
      input-id="dp-input-month-invoice"
      :label-text="$t('invoice.month')"
      :language="$i18n.locale"
      :minimum-view="'month'"
      :name="'month-invoice'"
      :col-label="'mr-3'"
      :col-input="'form-inline text-center'"
    />
    <div class="table-responsive table-stick-header">
      <table class="table table-multi-body table-bordered" :style="{ zIndex: 1, position: 'relative' }">
        <thead class="table-header">
          <tr class="text-center">
            <th rowspan="2" class="w-70px check-all">
              <div class="form-check">
                <input id="check-all" v-model="checkAll" type="checkbox" class="form-check-input" />
                <label class="form-check-label" for="check-all" />
              </div>
            </th>
            <th rowspan="2">
              {{ $t("invoice.building_unit") }}
            </th>
            <th rowspan="2">
              {{ $t("invoice.deposited") }}
            </th>
            <th rowspan="2">
              {{ $t("invoice.price") }}
            </th>
            <th rowspan="2" class="w-75px">
              {{ $t("invoice.day_used_per_month") }}
            </th>
            <th colspan="4">
              {{ $t("invoice.electric") }}
            </th>
            <th colspan="4">
              {{ $t("invoice.water") }}
            </th>
            <th rowspan="2">
              {{ $t("invoice.internet") }}
            </th>
            <th rowspan="2">
              {{ $t("invoice.unit_price_parking_fee") }}
            </th>
            <th rowspan="2">
              {{ $t("invoice.num") }}
            </th>
            <th rowspan="2">
              {{ $t("invoice.service") }}
            </th>
            <th rowspan="2">
              {{ $t("invoice.total") }}
            </th>
            <th rowspan="2">
              {{ $t("invoice.reduce") }}
            </th>
            <th rowspan="2">
              {{ $t("invoice.added") }}
            </th>
            <th rowspan="2">
              {{ $t("invoice.holder") }}
            </th>
            <th rowspan="2">
              {{ $t("invoice.note") }}
            </th>
          </tr>
          <tr>
            <th>
              {{ $t("invoice.begin_number") }}
            </th>
            <th>
              {{ $t("invoice.end_number") }}
            </th>
            <th>{{ $t("invoice.use") }}</th>
            <th>
              {{ $t("invoice.into_money") }}
            </th>
            <th>
              {{ $t("invoice.begin_number") }}
            </th>
            <th>
              {{ $t("invoice.end_number") }}
            </th>
            <th>{{ $t("invoice.use") }}</th>
            <th>
              {{ $t("invoice.into_money") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <RowInvoiceForm
            v-for="(item, unit_number) in invoicesForm"
            :key="unit_number"
            :item="item"
            :building-unit-name="unit_number"
            :month="month"
            :check-all="checkAll"
            @checked="updateValue($event)"
            @input="invalidateForm()"
          />
        </tbody>
      </table>
    </div>
    <button v-if="isValid" class="btn btn-primary float-right mb-2">
      {{ $t("invoice.submit_form") }}
    </button>
    <button v-else class="btn btn-primary float-right mb-2">
      {{ $t("invoice.check_form") }}
    </button>
  </form>
</template>

<script>
import { mapState } from "vuex"
import { show_flash_mixins } from "../../mixins"
import { some, forEach } from "lodash"

import RowInvoiceForm from "./rowInvoiceForm.vue"

export default {
  components: {
    RowInvoiceForm,
  },
  mixins: [show_flash_mixins],
  data: function () {
    return {
      flashMsg: "",
      isValid: false,
      month: new Date(),
      checkAll: false,
      listChecked: {},
    }
  },
  computed: {
    ...mapState({
      invoicesForm: (state) => state.invoice.invoicesForm,
      inputForm: (state) => state.invoice.inputForm,
      flashStateMsg: (state) => state.invoice.flashMsg,
    }),
  },
  watch: {
    listChecked(_val) {
      this.isValid = false
    },
    month(_val) {
      this.isValid = false
      this.$store.dispatch("invoice/getInvoiceForm", {
        month: `${this.month.getMonth() + 1}/${this.month.getFullYear()}`,
      })
    },
    checkAll(_val) {
      this.isValid = false
    },
  },
  created: function () {
    this.$store.dispatch("invoice/getInvoiceForm", {
      month: `${this.month.getMonth() + 1}/${this.month.getFullYear()}`,
    })
  },
  methods: {
    checkForm: function () {
      if (this.isValid) {
        this.submitForm()
      } else {
        this.validForm()
      }
    },
    validForm: function () {
      const self = this
      if (this.month === "") {
        this.flashMsg = this.$t("invoice.input_month")
        this.show_flash(false)

        return
      } else if (
        some(this.listChecked, (val) => {
          return val
        })
      ) {
        this.isValid = true
      } else {
        this.flashMsg = this.$t("invoice.choose_building_unit")
        this.show_flash(false)

        return
      }

      forEach(this.inputForm, function (item, unitNumber) {
        if (!self.listChecked[unitNumber]) return

        const validEle = item.electric.end_number >= item.electric.begin_number
        const validWat = item.water.end_number >= item.water.begin_number
        self.isValid =
          item.electric.total >= 0 && item.water.total >= 0 && item.invoice.total >= 0 && validEle && validWat
        if (!self.isValid) {
          self.flashMsg = self.$t("invoice.error_msg", { building_unit: unitNumber })
          return false
        }
      })

      if (this.isValid) {
        this.flashMsg = this.$t("invoice.success_msg")
        this.show_flash(true)
      } else {
        this.show_flash(false)
      }
    },
    submitForm: async function () {
      const self = this
      const submitForm = {}
      if (!this.isValid) return

      forEach(this.inputForm, function (item, unitNumber) {
        if (!self.listChecked[unitNumber]) return

        submitForm[unitNumber] = item
      })

      const month = `${this.month.getMonth() + 1}/${this.month.getFullYear()}`
      await this.$store.dispatch("invoice/createInvoices", {
        params: submitForm,
        month: month,
      })
      if (!self.flashStateMsg) return

      self.flashMsg = self.flashStateMsg
      self.show_flash(false)
    },
    updateValue(event) {
      this.listChecked[event.unitNumber] = event.check
    },
    invalidateForm() {
      this.isValid = false
    },
  },
}
</script>

<style lang="scss" scoped>
.table-stick-header {
  height: 800px;
}
thead {
  position: sticky;
  top: 0;
  z-index: 1000;
}
</style>
