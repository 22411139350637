<template>
  <div class="table-responsive">
    <table class="table table-hover">
      <thead class="table-header">
        <tr class="text-center">
          <th scope="col">
            {{ $t('contract_history.id') }}
          </th>
          <th scope="col">
            {{ $t('contract_history.time') }}
          </th>
          <th scope="col">
            {{ $t('contract_history.building_unit') }}
          </th>
          <th scope="col">
            {{ $t('contract_history.type_of_transaction') }}
          </th>
          <th scope="col">
            {{ $t('contract_history.holder_name') }}
          </th>
          <th scope="col">
            {{ $t('contract_history.host') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <RowContractHistory
          v-for="(contractHistory, index) in contractHistories"
          :key="index"
          :contract-history="contractHistory"
          @click_contract="showContractDetail"
          @click_user="showUserDetail"
        />
      </tbody>
      <DetailContract
        ref="detail_contract"
      />
      <DetailUser
        ref="detail_user"
      />
    </table>
  </div>
</template>

<script>
import RowContractHistory from './rowContractHistory.vue';
import DetailContract from '../../component_contracts/detailContract.vue';
import DetailUser from '../../component_users/showUser.vue';

export default {
  components: {
    RowContractHistory,
    DetailContract,
    DetailUser
  },
  props: {
    contractHistories: {
      type: Array,
      default: () => {[];}
    }
  },
  methods: {
    showContractDetail() {
      const element = this.$refs.detail_contract.$el;
      $(element).modal('show');
    },
    showUserDetail() {
      const element = this.$refs.detail_user.$el;
      $(element).modal('show');
    }
  }
};
</script>
