<template>
  <div class="form-group row">
    <label
      :for="inputId"
      :class="['col-form-label', colLabel]"
    >{{ labelText }}</label>
    <div :class="[colInput]">
      <transition
        name="fade"
        mode="out-in"
      >
        <div
          v-if="!image"
          :key="'input_image_file_' + inputId"
        >
          <input
            :id="inputId"
            ref="fileInput"
            :multiple="multiple"
            :accept="accept"
            type="file"
            :disabled="disabled"
            class="d-none"
            @change="onFileChange"
          >
          <button
            type="button"
            class="btn btn-light"
            @click="triggerFileInput"
          >
            <i
              class="fa fa-upload"
              aria-hidden="true"
            />
            {{ $t('input_image_file.select_file') }}
          </button>
          <small>
            {{ placeHolder }}
          </small>
        </div>
        <div
          v-else
          :key="'input_image_file_1_' + inputId"
        >
          <img
            :src="image"
            :width="width"
            :height="height"
            class="img-thumbnail"
          >
          <span>{{ fileName }}</span>
          <button
            class="btn btn-light"
            @click.prevent="removeImage"
          >
            {{ $t('input_image_file.remove') }}
            <i
              class="fa fa-times-circle"
              aria-hidden="true"
            />
          </button>
        </div>
      </transition>
      <span
        v-if="eMsg"
        class="text-danger"
      >{{ eMsg }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputImageFile',
  props: {
    inputId: {
      type: String,
      default: '',
      required: true
    },
    labelText: {
      type: String,
      default: '',
      required: true
    },
    eMsg: {
      type: String,
      default: ''
    },
    accept: {
      type: String,
      default: '',
      required: true
    },
    colLabel: {
      type: String,
      default: 'col-lg-2'
    },
    colInput: {
      type: String,
      default: 'col-lg-10'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    width: {
      type: [Number, String],
      default: 100
    },
    height: {
      type: [Number, String],
      default: 100
    },
    placeHolder: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: [File, Object],
      default: null
    }
  },
  emits: ['change', 'removeMsg', 'update:modelValue'],
  data() {
    return {
      image: '',
      fileName: ''
    };
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.$emit('change', files[0]);
      this.$emit('update:modelValue', files[0]);
      this.createImage(files[0]);
    },
    createImage(file) {
      const reader = new FileReader();
      const self = this;

      reader.onload = (e) => {
        self.image = e.target.result;
        self.fileName = file.name;
      };
      reader.readAsDataURL(file);
    },
    removeImage() {
      this.image = '';
      this.$emit('removeMsg');
      this.$emit('update:modelValue', null);
    }
  }
};
</script>

<style lang="scss" scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
