<template>
  <div id="app">
    <div class="container">
      <FlashMessage :position="'right top'" />
      <h1 class="mt-2">
        {{ $t("admin.profile") }}
      </h1>
      <div class="row">
        <div class="col-8">
          <form id="edit-admin-profile" @submit.prevent="submitForm">
            <FieldConfirm
              class="w-100"
              :label-text="$t('admin.email')"
              col-label="col-lg-12 font-weight-bold"
              col-input="col-lg-12"
              :value="params.email"
            />
            <InputText
              v-model:value-input="params.fullName"
              class="w-100"
              col-label="col-lg-12 font-weight-bold"
              col-input="col-lg-12"
              input-id="full_name"
              :label-text="$t('admin.full_name')"
              :value-input="params.fullName"
              :e-msg="toString(editAdminProfileErrors['full_name'])"
            />
            <InputText
              v-model:value-input="params.phone"
              class="w-100"
              col-label="col-lg-12 font-weight-bold"
              col-input="col-lg-12"
              input-id="phone"
              :label-text="$t('admin.phone')"
              :value-input="params.phone"
              :e-msg="toString(editAdminProfileErrors['phone'])"
            />
            <InputText
              v-model:value-input="params.birthday"
              class="w-100"
              col-label="col-lg-12 font-weight-bold"
              col-input="col-lg-12"
              input-id="birthday"
              type-input="date"
              :label-text="$t('admin.birthday')"
              :e-msg="toString(editAdminProfileErrors['birthday'])"
            />
            <div class="form-group row w-100">
              <div class="col-lg-12">
                <button class="btn btn-lg btn-success col-lg-12">
                  {{ $t("admin.save") }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import admin from "./api/admins"
import { show_flash_mixins, format_string } from "./mixins"

export default {
  mixins: [show_flash_mixins, format_string],
  data() {
    return {
      params: {
        fullName: "",
        phone: "",
        birthday: "",
      },
      editAdminProfileErrors: {},
    }
  },
  created: function () {
    const adminId = document.getElementById('adminId').value
    admin.loadDetailAdmin({ id: adminId }, (data) => {
      const self = this
      self.params.fullName = data.admin.full_name
      self.params.phone = data.admin.phone
      self.params.birthday = data.admin.birthday
      self.params.email = data.admin.email
    })
  },
  methods: {
    submitForm() {
      const self = this
      const payload = {
        full_name: self.params.fullName,
        phone: self.params.phone,
        birthday: self.params.birthday,
      }
      admin.updateAdmin(payload, (data) => {
        self.editAdminProfileErrors = {}
        if (data.error) {
          self.editAdminProfileErrors = data.error
        } else {
          self.show_flash(true)
        }
      })
    },
  },
}
</script>
