// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
window.ujs = require('@rails/ujs')
// ujs.start()
window.numeral = require("numeral")
window.domtoimage = require("dom-to-image")
window.NProgress = require("nprogress")
NProgress.configure({ showSpinner: false })

// Import debug script
import { testComponentInitialization } from "../debug"

import "bootstrap"
import "select2"
import "bootstrap-fileinput/js/fileinput.min.js"
import "../common/base"

// Import Vue and plugins
import { createApp } from "vue"
import { createI18n } from "vue-i18n"
import { store } from "../store"
import ComponentsPlugin from "../plugins/components"
import FlashMessagePlugin from "../plugins/flash-message-plugin"

// Import vue-awesome-paginate
import VueAwesomePaginate from "vue-awesome-paginate"
import "vue-awesome-paginate/dist/style.css"

// Import language utilities
import { getCurrentLang } from "../utils/language"

// Import components
import Users from "../userApp.vue"
import NewUser from "../newUser.vue"
import Floors from "../floorApp.vue"
import Contracts from "../contractApp.vue"
import Invoices from "../invoiceApp.vue"
import BatchCreateNewInvoices from "../batchCreateNewInvoice.vue"
import PrintInvoice from "../printInvoice.vue"
import NewInvoice from "../newInvoice.vue"
import RevenueStatistics from "../revenueStatistics.vue"
import ContractHistoryStatistics from "../contractHistoryStatistics.vue"
import ListInvoices from "../listInvoices.vue"
import EditAdminProfile from "../editAdminProfile.vue"
import Organizations from "../organizationsApp.vue"

// Get the locale from the HTML tag
const locale = getCurrentLang()

document.addEventListener("DOMContentLoaded", () => {
  // Run the component initialization test
  console.log(testComponentInitialization())

  const elementUser = document.getElementById("userApp")
  const elementNewUser = document.getElementById("newUserApp")
  const elementFloor = document.getElementById("floorApp")
  const elementContract = document.getElementById("contractApp")
  const elementInvoice = document.getElementById("invoiceApp")
  const elementBatchCreateNewInvoiceApp = document.getElementById("batchCreateNewInvoiceApp")
  const elementPrintInvoiceApp = document.getElementById("printInvoiceApp")
  const elementNewInvoice = document.getElementById("newInvoiceApp")
  const elementRevenueStatistics = document.getElementById("revenueStatisticsApp")
  const elementContractHistoryStatistics = document.getElementById("contractHistoryStatisticsApp")
  const elementListInvoices = document.getElementById("listInvoicesApp")
  const elementEditAdminProfileApp = document.getElementById("editAdminProfileApp")
  const organizationsApp = document.getElementById("organizationsApp")

  const i18n = createI18n({
    locale: locale,
    allowComposition: true,
    messages: loadLocaleMessages(),
  })

  // Create a Vue app with common plugins
  const createVueApp = function (component, selector) {
    try {
      const app = createApp(component)
      app.use(store)
      app.use(i18n)
      app.use(ComponentsPlugin)
      app.use(FlashMessagePlugin)
      app.use(VueAwesomePaginate)

      // Make the store globally available
      app.config.globalProperties.$store = store

      return app.mount(selector)
    } catch (error) {
      console.error("Error creating Vue app:", error)
      throw error
    }
  }

  if (elementUser != null) {
    createVueApp(Users, elementUser)
  }

  if (elementNewUser != null) {
    createVueApp(NewUser, elementNewUser)
  }

  if (elementFloor != null) {
    createVueApp(Floors, elementFloor)
  }

  if (elementContract != null) {
    createVueApp(Contracts, elementContract)
  }

  if (elementBatchCreateNewInvoiceApp != null) {
    try {
      const app = createApp(BatchCreateNewInvoices)
      app.use(store)
      app.use(i18n)
      app.use(ComponentsPlugin)
      app.use(FlashMessagePlugin)
      app.use(VueAwesomePaginate)

      // Make the store globally available
      app.config.globalProperties.$store = store

      app.provide("component", elementBatchCreateNewInvoiceApp.getAttribute("component"))
      app.mount(elementBatchCreateNewInvoiceApp)
    } catch (error) {
      console.error("Error creating BatchCreateNewInvoices app:", error)
    }
  }

  if (elementInvoice != null) {
    createVueApp(Invoices, elementInvoice)
  }

  if (elementPrintInvoiceApp != null) {
    createVueApp(PrintInvoice, elementPrintInvoiceApp)
  }

  if (elementNewInvoice != null) {
    createVueApp(NewInvoice, elementNewInvoice)
  }

  if (elementRevenueStatistics != null) {
    createVueApp(RevenueStatistics, elementRevenueStatistics)
  }

  if (elementContractHistoryStatistics != null) {
    createVueApp(ContractHistoryStatistics, elementContractHistoryStatistics)
  }

  if (elementListInvoices != null) {
    createVueApp(ListInvoices, elementListInvoices)
  }

  if (elementEditAdminProfileApp != null) {
    createVueApp(EditAdminProfile, elementEditAdminProfileApp)
  }

  if (organizationsApp != null) {
    createVueApp(Organizations, organizationsApp)
  }
})

function loadLocaleMessages() {
  try {
    return require(`../locales/modules/${locale}`).default
  } catch (e) {
    console.error(`Could not load locale ${locale}, falling back to en`, e)
    return require("../locales/modules/en").default
  }
}

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
