<template>
  <section class="create-invoices">
    <FlashMessage :status="submitStatus" :message="submitMessage" @onCloseFlash="onCloseFlash" />
    <button v-show="loaded" class="btn btn-dark float-right mb-2" @click="exportCSVFile">
      {{ $t("export.csv") }}
    </button>
    <InputDate
      v-model:value-input="month"
      :input-id="'month-invoice'"
      :label-text="$t('invoice.month')"
      :language="$i18n.locale"
      :minimum-view="'month'"
      :name="'list-invoice-date'"
      :col-label="'mr-3'"
      :col-input="'form-inline text-center'"
    />
    <div v-show="loaded" class="hot-table-invoices">
      <hot-table ref="invoices" :settings="hotSettings" />
    </div>
  </section>
</template>

<script>
import axios from "axios"
import $ from "jquery"
import { isEmpty, forEach } from "lodash"
import { HotTable } from "@handsontable/vue3"
import { registerAllModules } from "handsontable/registry"
import { HyperFormula } from "hyperformula"

const formatNumber = {
  pattern: {
    thousandSeparated: true,
    mantissa: 0,
  },
}

registerAllModules()

export default {
  components: {
    HotTable,
  },
  data() {
    return {
      hotSettings: {
        height: "auto",
        stretchH: "all",
        className: "htCenter htMiddle",
        rowHeights: 30,
        rowHeaders: true,
        manualColumnResize: true,
        nestedHeaders: [
          [
            "Phòng",
            "Đặt cọc",
            "Tiền phòng",
            "Ngày sử dụng",
            { label: "Điện", colspan: 4 },
            { label: "Nước", colspan: 4 },
            { label: "Internet", colspan: 2 },
            { label: "Dịch vụ", colspan: 2 },
            { label: "Phí gửi xe", colspan: 3 },
            "Tổng tiền",
            "Giảm trừ",
            "Tăng thêm",
            "Chủ phòng",
            "Ghi chú",
          ],
          [
            "",
            "",
            "",
            "",
            "Số đầu",
            "Số cuối",
            "Sử dụng",
            "Thành tiền",
            "Số đầu",
            "Số cuối",
            "Sử dụng",
            "Thành tiền",
            "Đơn giá",
            "Thành tiền",
            "Đơn giá",
            "Thành tiền",
            "Đơn giá",
            "Số lượng",
            "Thành tiền",
            "",
            "",
          ],
          [
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "J",
            "K",
            "L",
            "M",
            "N",
            "O",
            "P",
            "Q",
            "R",
            "S",
            "T",
            "U",
            "V",
            "W",
            "X",
          ],
        ],
        columns: [
          { data: "key", readOnly: true },
          { data: "deposited_money", readOnly: true, type: "numeric", numericFormat: formatNumber },
          { data: "building_unit_price", readOnly: true, type: "numeric", numericFormat: formatNumber },
          { data: "day_used_per_month", readOnly: true, type: "numeric" },
          { data: "ele_begin_number", readOnly: true, type: "numeric", numericFormat: formatNumber },
          { data: "ele_end_number", readOnly: true, type: "numeric", numericFormat: formatNumber },
          { data: "ele_qty", readOnly: true, type: "numeric", numericFormat: formatNumber },
          { data: "ele_total", readOnly: true, type: "numeric", numericFormat: formatNumber },
          { data: "wat_begin_number", readOnly: true, type: "numeric", numericFormat: formatNumber },
          { data: "wat_end_number", readOnly: true, type: "numeric", numericFormat: formatNumber },
          { data: "wat_qty", readOnly: true, type: "numeric", numericFormat: formatNumber },
          { data: "wat_total", readOnly: true, type: "numeric", numericFormat: formatNumber },
          { data: "internet_unit", readOnly: true, type: "numeric", numericFormat: formatNumber },
          { data: "internet_total", readOnly: true, type: "numeric", numericFormat: formatNumber },
          { data: "service_unit", readOnly: true, type: "numeric", numericFormat: formatNumber },
          { data: "service_total", readOnly: true, type: "numeric", numericFormat: formatNumber },
          { data: "parking_fee_unit", readOnly: true, type: "numeric", numericFormat: formatNumber },
          { data: "parking_fee_qty", readOnly: true, type: "numeric" },
          { data: "parking_fee_total", readOnly: true, type: "numeric", numericFormat: formatNumber },
          { data: "total", readOnly: true, type: "numeric", numericFormat: formatNumber },
          { data: "reduce", readOnly: true, type: "numeric", numericFormat: formatNumber },
          { data: "added", readOnly: true, type: "numeric", numericFormat: formatNumber },
          { data: "holder_name", readOnly: true },
          { data: "note", readOnly: true },
        ],
        formulas: {
          engine: HyperFormula,
        },
        licenseKey: "non-commercial-and-evaluation",
      },
      month: new Date(),
      isValid: false,
      hotRef: "",
      loaded: false,
      submitStatus: "",
      submitMessage: "",
    }
  },
  computed: {
    totalDayInMonth() {
      return new Date(new Date(this.month).getFullYear(), new Date(this.month).getMonth() + 1, 0).getDate()
    },
  },
  watch: {
    async month(val) {
      this.submitStatus = true
      this.submitMessage = this.$t("invoice.loadding")
      const month = `${val.getMonth() + 1}/${val.getFullYear()}`
      const response = await axios.get("/list_invoices.json", {
        params: { invoices: { month } },
        paramsSerializer: function (params) {
          return $.param(params)
        },
      })
      const { data, status } = response
      if (status == 200) {
        this.updateDataHotSetting(data.build)
      }
    },
  },
  async created() {
    this.submitStatus = true
    this.submitMessage = this.$t("invoice.loadding")

    const month = `${this.month.getMonth() + 1}/${this.month.getFullYear()}`
    const response = await axios.get("/list_invoices.json", {
      params: { invoices: { month } },
      paramsSerializer: function (params) {
        return $.param(params)
      },
    })
    const { data, status } = response
    if (status == 200) {
      this.updateDataHotSetting(data.build)
    }
  },
  methods: {
    updateDataHotSetting(data) {
      this.loaded = true

      const result = this.formatDataHotSetting(data)
      this.$nextTick(() => {
        this.hotRef = this.$refs.invoices.hotInstance
        this.hotSettings.data = result

        this.hotRef.updateSettings(this.hotSettings)
      })
      this.submitStatus = true
      this.submitMessage = this.$t("invoice.loaded")
    },
    formatDataHotSetting(data) {
      const dataInit = []
      let index = 1
      forEach(data, (rawData, key) => {
        if (!isEmpty(rawData)) {
          dataInit.push({
            key: key,
            deposited_money: rawData.contract.deposited_money || "",
            building_unit_price: rawData.contract.building_unit_price,
            day_used_per_month: rawData.invoice.day_used_per_month,
            ele_begin_number: rawData.electric.begin_number,
            ele_end_number: rawData.electric.end_number,
            ele_qty: `=IF(F${index} > E${index}, F${index} - E${index}, 0)`,
            ele_total: `=G${index} * ${rawData.electric.unit_price}`,
            wat_begin_number: rawData.water.begin_number,
            wat_end_number: rawData.water.end_number,
            wat_qty: `=IF(J${index} > I${index}, J${index} - I${index}, 0)`,
            wat_total: `=K${index} * ${rawData.water.unit_price}`,
            internet_unit: rawData.internet.unit_price,
            internet_total: `=D${index} * M${index} / ${this.totalDayInMonth}`,
            service_unit: rawData.service.unit_price,
            service_total: `=D${index} * O${index} / ${this.totalDayInMonth}`,
            parking_fee_unit: rawData.parking_fee.unit_price,
            parking_fee_qty: rawData.parking_fee.quantity,
            parking_fee_total: `=D${index} * Q${index} * R${index} / ${this.totalDayInMonth}`,

            total: `=D${index} * C${index} / ${this.totalDayInMonth} + H${index} + L${index} + N${index} + P${index} + S${index} - U${index} + V${index}`,
            reduce: rawData.invoice.reduce,
            added: rawData.invoice.added,
            holder_name: rawData.extend_data.holder_name,
            note: "",
          })
        } else {
          dataInit.push({
            key: key,
            deposited_money: "",
            building_unit_price: "",
            day_used_per_month: "",
            ele_begin_number: "",
            ele_end_number: "",
            ele_qty: "",
            ele_total: "",
            wat_begin_number: "",
            wat_end_number: "",
            wat_qty: "",
            wat_total: "",
            internet_unit: "",
            internet_total: "",
            service_unit: "",
            service_total: "",
            parking_fee_unit: "",
            parking_fee_qty: "",
            parking_fee_total: "",
            total: "",
            reduce: "",
            added: "",
            holder_name: "",
            note: "",
          })
        }

        index++
      })

      const totalRow = index - 1
      dataInit.push({
        key: "Tổng cộng",
        deposited_money: `=SUM(B1: B${totalRow})`,
        building_unit_price: `=SUM(C1: C${totalRow})`,
        day_used_per_month: "",
        ele_begin_number: "",
        ele_end_number: "",
        ele_qty: `=SUM(G1: G${totalRow})`,
        ele_total: `=SUM(H1: H${totalRow})`,
        wat_begin_number: "",
        wat_end_number: "",
        wat_qty: `=SUM(K1: K${totalRow})`,
        wat_total: `=SUM(L1: L${totalRow})`,
        internet_unit: "",
        internet_total: `=SUM(N1: N${totalRow})`,
        service_unit: "",
        service_total: `=SUM(P1: P${totalRow})`,
        parking_fee_unit: "",
        parking_fee_qty: `=SUM(R1: R${totalRow})`,
        parking_fee_total: `=SUM(S1: S${totalRow})`,
        total: `=SUM(T1: T${totalRow})`,
        reduce: `=SUM(U1: U${totalRow})`,
        added: `=SUM(V1: V${totalRow})`,
        holder_name: "",
        note: "",
      })

      return dataInit
    },
    exportCSVFile() {
      const exportPlugin = this.hotRef.getPlugin("exportFile")
      exportPlugin.downloadFile("csv", {
        bom: false,
        columnDelimiter: ",",
        columnHeaders: true,
        exportHiddenColumns: false,
        exportHiddenRows: false,
        fileExtension: "csv",
        filename: `bms_${this.month.getMonth() + 1}${this.month.getFullYear()}_paycheck`,
        mimeType: "text/csv",
        rowDelimiter: "\r\n",
        rowHeaders: true,
      })
    },
    onCloseFlash() {
      this.submitStatus = ""
      this.submitMessage = ""
    },
  },
}
</script>
<style scoped type="scss">
:deep(.htDimmed) {
  color: #373737;
}
</style>
