<template>
  <div id="app">
    <search-form />
    <div :class="['d-flex', {'mb-2': !showPaginate}]">
      <div class="ml-auto">
        <paginator
          :current-page="currentPage"
          :show-paginate="showPaginate"
          :total-pages="totalPages"
          :total-count="totalCount"
          @update:current-page="(page) => loadRevenues(page)"
        />
      </div>
      <br>
    </div>
    <table-result :revenues="revenues" />
    <div class="d-flex flex-row-reverse">
      <paginator
        :current-page="currentPage"
        :show-paginate="showPaginate"
        :total-pages="totalPages"
        :total-count="totalCount"
        @update:current-page="(page) => loadRevenues(page)"
      />
    </div>
  </div>
</template>

<script>
import $ from "jquery"
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('revenue');

import TableResult from './components_statistics/revenues/tableResult.vue';
import SearchForm from './components_statistics/revenues/searchForm.vue';

export default {
  components: {
    SearchForm,
    TableResult,
  },
  computed: {
    ...mapState(['revenues', 'currentPage', 'totalPages',
                 'totalCount', 'showPaginate', 'params'])
  },
  mounted() {
    this.getOptionBuildingUnits();
  },
  methods: {
    ...mapActions(['getOptionBuildingUnits', 'submitFormSearch']),
    async loadRevenues(current_page) {
      $("#loading").removeClass("d-none");
      $("#body-content").addClass("body-opacity-50");
      await this.submitFormSearch({ params: this.params, page: current_page });
      $("#loading").addClass("d-none");
      $("#body-content").removeClass("body-opacity-50");
    }
  }
};
</script>
