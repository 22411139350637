<template>
  <div class="new-invoice">
    <div>
      <div>
        <h3>{{ $t("invoice.new_invoice") }}</h3>
      </div>
      <hr />
      <div>
        <form id="new-invoice" class="w-90 ml-auto mr-auto" @submit.prevent="submitForm">
          <div class="form-input p-2">
            <div class="form-row">
              <div class="form-group col-md-6">
                <InputSelect2
                  v-model:selected="buildingUnitId"
                  :input-id="'building_unit_id'"
                  :label-text="$t('invoice.unit_number')"
                  :place-holder="$t('invoice.select_building_unit')"
                  :options="collectionBuildingUnits"
                  :e-msg="deliverErrors('building_unit_id')"
                  type-select="single_select"
                  col-label="col-lg-4"
                  col-input="col-lg-6"
                />
              </div>
              <div class="form-group col-md-6">
                <FieldConfirm
                  :label-text="$t('invoice.building_unit_price')"
                  :value="formatNumber(newInvoice.building_unit_price)"
                  col-label="col-md-4"
                  col-input="col-md-6"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <InputDate
                  v-model:value-input="month"
                  :input-id="'month'"
                  :label-text="$t('invoice.month')"
                  :e-msg="deliverErrors('month')"
                  :language="$i18n.locale"
                  :minimum-view="'month'"
                  :maximum-view="'year'"
                  col-label="col-lg-4"
                  col-input="col-lg-6"
                />
              </div>
              <div class="form-group col-md-6">
                <InputNumberCurrency
                  v-model:value-input="dayUsedPerMonth"
                  :input-id="'used_building_unit_quantity'"
                  :label-text="$t('invoice.day_used_per_month')"
                  :input-mode="'numeric'"
                  :e-msg="deliverErrors('day_used_per_month')"
                  col-label="col-lg-4"
                  col-input="col-lg-6"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <InputNumberCurrency
                  v-model:value-input="electricStart"
                  :input-id="'electric_start'"
                  :label-text="$t('invoice.electric_start')"
                  :input-mode="'numeric'"
                  :e-msg="deliverErrors('electric_start')"
                  col-label="col-lg-4"
                  col-input="col-lg-6"
                />
              </div>
              <div class="form-group col-md-6">
                <InputNumberCurrency
                  v-model:value-input="electricEnd"
                  :input-id="'electric_end'"
                  :label-text="$t('invoice.electric_end')"
                  :input-mode="'numeric'"
                  :e-msg="deliverErrors('electric_end')"
                  col-label="col-lg-4"
                  col-input="col-lg-6"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <InputNumberCurrency
                  v-model:value-input="waterStart"
                  :input-id="'water_start'"
                  :label-text="$t('invoice.water_start')"
                  :input-mode="'numeric'"
                  :e-msg="deliverErrors('water_start')"
                  col-label="col-lg-4"
                  col-input="col-lg-6"
                />
              </div>
              <div class="form-group col-md-6">
                <InputNumberCurrency
                  v-model:value-input="waterEnd"
                  :input-id="'water_end'"
                  :label-text="$t('invoice.water_end')"
                  :input-mode="'numeric'"
                  :e-msg="deliverErrors('water_end')"
                  col-label="col-lg-4"
                  col-input="col-lg-6"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <InputNumberCurrency
                  v-model:value-input="unitParkingFee"
                  :input-id="'unit_price_parking_fee'"
                  :label-text="$t('invoice.unit_price_parking_fee')"
                  :input-mode="'numeric'"
                  :e-msg="deliverErrors('unit_price_parking_fee')"
                  col-label="col-lg-4"
                  col-input="col-lg-6"
                />
              </div>
              <div class="form-group col-md-6">
                <InputNumberCurrency
                  v-model:value-input="quantityParking"
                  :input-id="'quantity_parking'"
                  :label-text="$t('invoice.quantity_parking')"
                  :input-mode="'numeric'"
                  :e-msg="deliverErrors('quantity_parking')"
                  col-label="col-lg-4"
                  col-input="col-lg-6"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <InputNumberCurrency
                  v-model:value-input="unitPriceInternet"
                  :input-id="'unit_price_internet'"
                  :label-text="$t('invoice.unit_price_internet')"
                  :input-mode="'numeric'"
                  :e-msg="deliverErrors('unit_price_internet')"
                  col-label="col-lg-4"
                  col-input="col-lg-6"
                />
              </div>
              <div class="form-group col-md-6">
                <InputNumberCurrency
                  v-model:value-input="serviceFee"
                  :input-id="'unit_price_service_fee'"
                  :label-text="$t('invoice.unit_price_service_fee')"
                  :input-mode="'numeric'"
                  :e-msg="deliverErrors('unit_price_service_fee')"
                  col-label="col-lg-4"
                  col-input="col-lg-6"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <InputText
                  v-model:value-input="reduce"
                  :input-id="'reduce'"
                  :label-text="$t('invoice.reduce')"
                  :input-mode="'numeric'"
                  :e-msg="deliverErrors('reduce')"
                  col-label="col-lg-4"
                  col-input="col-lg-6"
                />
              </div>
              <div class="form-group col-md-6">
                <InputText
                  v-model:value-input="added"
                  :input-id="'added'"
                  :label-text="$t('invoice.added')"
                  :input-mode="'numeric'"
                  :e-msg="deliverErrors('added')"
                  col-label="col-lg-4"
                  col-input="col-lg-6"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <InputNumberCurrency
                  v-model:value-input="total"
                  :input-id="'total'"
                  :label-text="$t('invoice.total')"
                  :e-msg="deliverErrors('total')"
                  :disabled="true"
                  col-label="col-lg-4"
                  col-input="col-lg-6"
                />
              </div>
            </div>
          </div>
          <button type="submit" class="btn btn-primary float-left mt-2">
            {{ $t("invoice.submit_form") }}
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
const { mapState, mapActions } = createNamespacedHelpers("invoice")
import { show_flash_mixins, format_number } from "../mixins"

export default {
  mixins: [show_flash_mixins, format_number],
  data: function () {
    return {
      month: new Date(),
      buildingUnitId: "",
      total: 0,
    }
  },
  computed: {
    ...mapState({
      collectionBuildingUnits: (state) => state.buildingUnits,
      newInvoice: (state) => state.newInvoice,
      errorMessages: (state) => state.errorMessages,
      flashMsg: (state) => state.flashMsg,
      loading: (state) => state.loading,
    }),
    eleTotal: function () {
      const electricQuantity = this.newInvoice.electric_end - this.newInvoice.electric_start
      return electricQuantity > 0 ? electricQuantity * this.newInvoice.electric_unit_price : 0
    },
    watTotal: function () {
      const waterQuantity = this.newInvoice.water_end - this.newInvoice.water_start
      return waterQuantity > 0 ? waterQuantity * this.newInvoice.water_unit_price : 0
    },
    totalDayInMonth: function () {
      return new Date(new Date(this.month).getFullYear(), new Date(this.month).getMonth() + 1, 0).getDate()
    },
    intTotal: function () {
      return (this.newInvoice.unit_price_internet * this.newInvoice.day_used_per_month) / this.totalDayInMonth
    },
    pafTotal: function () {
      return (
        (this.newInvoice.unit_price_parking_fee *
          this.newInvoice.day_used_per_month *
          this.newInvoice.quantity_parking) /
        this.totalDayInMonth
      )
    },
    serTotal: function () {
      return (this.newInvoice.unit_price_service_fee * this.newInvoice.day_used_per_month) / this.totalDayInMonth
    },
    buildingUnitPriceTotal: function () {
      return (this.newInvoice.building_unit_price * this.newInvoice.day_used_per_month) / this.totalDayInMonth
    },
    unitPriceInternet: {
      get() {
        return this.newInvoice.unit_price_internet || 0
      },
      set(val) {
        this.setInputNewInvoiceForm({ unit_price_internet: val })
        this.calculateTotal()
      },
    },
    serviceFee: {
      get() {
        return this.newInvoice.unit_price_service_fee || 0
      },
      set(val) {
        this.setInputNewInvoiceForm({ unit_price_service_fee: val })
        this.calculateTotal()
      },
    },
    unitParkingFee: {
      get() {
        return this.newInvoice.unit_price_parking_fee || 0
      },
      set(val) {
        this.setInputNewInvoiceForm({ unit_price_parking_fee: val })
        this.calculateTotal()
      },
    },
    quantityParking: {
      get() {
        return this.newInvoice.quantity_parking || 0
      },
      set(val) {
        this.setInputNewInvoiceForm({ quantity_parking: val })
        this.calculateTotal()
      },
    },
    reduce: {
      get() {
        return this.newInvoice.reduce || 0
      },
      set(val) {
        this.setInputNewInvoiceForm({ reduce: val })
        this.calculateTotal()
      },
    },
    added: {
      get() {
        return this.newInvoice.added || 0
      },
      set(val) {
        this.setInputNewInvoiceForm({ added: val })
        this.calculateTotal()
      },
    },
    electricStart: {
      get() {
        return this.newInvoice.electric_start || 0
      },
      set(val) {
        this.setInputNewInvoiceForm({ electric_start: val })
        this.calculateTotal()
      },
    },
    electricEnd: {
      get() {
        return this.newInvoice.electric_end || 0
      },
      set(val) {
        this.setInputNewInvoiceForm({ electric_end: val })
        this.calculateTotal()
      },
    },
    waterStart: {
      get() {
        return this.newInvoice.water_start || 0
      },
      set(val) {
        this.setInputNewInvoiceForm({ water_start: val })
        this.calculateTotal()
      },
    },
    waterEnd: {
      get() {
        return this.newInvoice.water_end || 0
      },
      set(val) {
        this.setInputNewInvoiceForm({ water_end: val })
        this.calculateTotal()
      },
    },
    dayUsedPerMonth: {
      get() {
        return this.newInvoice.day_used_per_month || 0
      },
      set(val) {
        this.setInputNewInvoiceForm({ day_used_per_month: val })
        this.calculateTotal()
      },
    },
  },
  watch: {
    month: function (val) {
      this.setInputNewInvoiceForm({
        month: `${new Date(val).getMonth() + 1}/${new Date(val).getFullYear()}`,
        day_used_per_month: this.totalDayInMonth,
      })
      this.getResourceInfo({ month: this.newInvoice.month, building_unit_id: this.buildingUnitId })
    },
    buildingUnitId: function (val) {
      this.setInputNewInvoiceForm({
        building_unit_id: val,
        month: `${new Date(this.month).getMonth() + 1}/${new Date(this.month).getFullYear()}`,
      })
      this.getResourceInfo({ month: this.newInvoice.month, building_unit_id: val })
    },
    total: function (val) {
      this.setInputNewInvoiceForm({ total: numeral(val).value() })
    },
    loading: function (val) {
      if (val) return

      this.calculateTotal()
    },
  },
  created: function () {
    this.getResourceInfo({})
  },
  methods: {
    ...mapActions(["createInvoice", "setInputNewInvoiceForm", "getResourceInfo"]),
    submitForm: async function () {
      await this.createInvoice(this.newInvoice)

      if (!this.flashMsg) return

      this.show_flash(false)
    },
    deliverErrors: function (attribute) {
      if (!this.errorMessages[0]) return

      return this.errorMessages[0][attribute]
    },
    calculateTotal: function () {
      this.total =
        this.buildingUnitPriceTotal +
        this.eleTotal +
        this.watTotal +
        this.intTotal +
        this.pafTotal +
        this.serTotal +
        this.newInvoice.added -
        this.newInvoice.reduce
    },
  },
}
</script>

<style lang="scss" scoped>
.form-input {
  border: 2px solid#e8e7e7;
  background-color: #f3f3f3;
}
</style>
