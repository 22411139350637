<template>
  <div class="form-group row">
    <label :for="`dp-input-${inputId}`" :class="['col-form-label', colLabel]">{{ labelText }}</label>
    <div :class="[colInput]">
      <VueDatepicker :uid="inputId" v-model="currentValue" :model-type="dateFormat" :name="name"
        input-class="form-control" :ui="{ calendar: wrapperClass }" :locale="language" :format="dateFormat"
        :month-picker="minimumView === 'month'" :year-picker="minimumView === 'year'" :disabled-dates="disabledDates"
        :focus-start-date="true" :max-date="maxDate" :min-date="minDate" :clearable="clearable"
        :auto-apply="autoApply" />
      <span v-if="eMsg" class="text-danger">{{ eMsg }}</span>
    </div>
  </div>
</template>

<script>
import VueDatepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  name: 'InputDate',
  components: {
    VueDatepicker
  },
  props: {
    inputId: {
      type: String,
      required: true
    },
    labelText: {
      type: String,
      required: true
    },
    valueInput: {
      type: Date,
      default: null
    },
    eMsg: {
      type: String,
      default: ''
    },
    colLabel: {
      type: String,
      default: 'col-lg-2'
    },
    colInput: {
      type: String,
      default: 'col-lg-10'
    },
    language: {
      type: String,
      default: 'vi'
    },
    dateFormat: {
      type: String,
      default: null
    },
    minimumView: {
      type: String,
      default: 'day'
    },
    maximumView: {
      type: String,
      default: 'year'
    },
    wrapperClass: {
      type: String,
      default: ''
    },
    disabledDates: {
      type: [Array, Function],
      default: []
    },
    name: {
      type: String,
      default: 'month_export-invoice'
    },
    maxDate: {
      type: [String, Date],
      default: undefined
    },
    minDate: {
      type: [String, Date],
      default: undefined
    },
    clearable: {
      type: Boolean,
      default: true
    },
    autoApply: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {}
  },
  emits: ['update:value-input'],
  computed: {
    currentValue: {
      get() {
        if (this.minimumView === 'month') {
          return { month: this.valueInput.getMonth(), year: this.valueInput.getFullYear() }
        } else if (this.minimumView === 'year') {
          return this.valueInput.getFullYear()
        }

        return this.valueInput;
      },
      set(value) {
        const emitValue = value;
        if (emitValue instanceof Date) {
          this.$emit('update:value-input', emitValue);
        } else if (emitValue.hasOwnProperty('month') && emitValue.hasOwnProperty('year')) {
          this.$emit('update:value-input', new Date(emitValue.year, emitValue.month));
        }
      }
    }
  },
};
</script>

<style scoped>
.z-1019 {
  z-index: 1019;
}
</style>
