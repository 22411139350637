<template>
  <tr class="text-center">
    <td>{{ revenue.date }}</td>
    <td>{{ formatNumber(revenue.building_unit_price) }}</td>
    <td>{{ formatNumber(revenue.electrict_total) }}</td>
    <td>{{ formatNumber(revenue.water_total) }}</td>
    <td>{{ formatNumber(revenue.internet_total) }}</td>
    <td>{{ formatNumber(revenue.park_fee_total) }}</td>
    <td>{{ formatNumber(revenue.service_total) }}</td>
    <td>{{ formatNumber(revenue.reduce) }}</td>
    <td>{{ formatNumber(revenue.added) }}</td>
    <td>{{ formatNumber(revenue.total) }}</td>
  </tr>
</template>

<script>
import { format_number } from "../../mixins"

export default {
  mixins: [format_number],
  props: {
    revenue: {
      type: Object,
      required: true,
    },
  },
}
</script>
