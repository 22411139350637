<template>
  <div class="table-responsive">
    <table class="table table-bordered table-striped">
      <thead class="table-header">
        <tr class="text-center">
          <th
            scope="col"
            class="align-middle"
          >
            {{ $t('revenues.month') }}
          </th>
          <th
            scope="col"
            class="align-middle"
          >
            {{ $t('revenues.building_unit_price') }}
          </th>
          <th
            scope="col"
            class="align-middle"
          >
            {{ $t('revenues.electric') }}
          </th>
          <th
            scope="col"
            class="align-middle"
          >
            {{ $t('revenues.water') }}
          </th>
          <th
            scope="col"
            class="align-middle"
          >
            {{ $t('revenues.internet') }}
          </th>
          <th
            scope="col"
            class="align-middle"
          >
            {{ $t('revenues.price_parking_fee') }}
          </th>
          <th
            scope="col"
            class="align-middle"
          >
            {{ $t('revenues.service') }}
          </th>
          <th
            scope="col"
            class="align-middle"
          >
            {{ $t('revenues.reduce') }}
          </th>
          <th
            scope="col"
            class="align-middle"
          >
            {{ $t('revenues.added') }}
          </th>
          <th
            scope="col"
            class="align-middle"
          >
            {{ $t('revenues.total_income') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <row-revenue
          v-for="(revenue, index) in revenues"
          :key="index"
          :revenue="revenue"
        />
      </tbody>
    </table>
  </div>
</template>

<script>
import RowRevenue from './rowRevenue.vue';

export default {
  components: {
    RowRevenue
  },
  props: {
    revenues: {
      type: Array,
      required: true
    }
  }
};
</script>
