<template>
  <table
    class="table table-bordered table-striped"
    @contextmenu.prevent="$refs.menu.open($event)"
  >
    <ContextMenu ref="menu">
      <ContextMenuItem @click="editUser()">
        {{ $t('user.edit_user') }}
      </ContextMenuItem>
      <ContextMenuItem @click="deleteUser()">
        {{ $t('user.delete_user') }}
      </ContextMenuItem>
    </ContextMenu>
    <thead class="table-header">
      <tr class="text-center">
        <th class="w-100px align-middle">
          {{ $t('user.user_id') }}
        </th>
        <th class="w-200px align-middle">
          {{ $t('user.first_name') }}
        </th>
        <th class="w-200px align-middle">
          {{ $t('user.last_name') }}
        </th>
        <th class="w-150px align-middle">
          {{ $t('user.unit_number') }}
        </th>
        <th class="w-200px align-middle">
          {{ $t('user.phone') }}
        </th>
        <th class="w-120px align-middle">
          {{ $t('user.birthday') }}
        </th>
        <th class="w-120px align-middle">
          {{ $t('user.created_at') }}
        </th>
        <th class="w-120px align-middle">
          {{ $t('user.edit_or_delete') }}
        </th>
        <th class="w-60px align-middle">
          {{ $t('user.active') }}
        </th>
      </tr>
    </thead>
    <tbody>
      <row-user
        v-for="(user, index) in users"
        :key="index"
        :user="user"
      />
    </tbody>
    <edit-user ref="modal_edit" />
    <delete-user ref="modal_delete" />
  </table>
</template>

<script>
import RowUser from './rowUser.vue';
import EditUser from './editUser.vue';
import DeleteUser from './deleteUser.vue';
import ContextMenu from '../components/contextMenu/contextMenu.vue';
import ContextMenuItem from '../components/contextMenu/contextMenuItem.vue';

export default {
  components: {
    RowUser,
    EditUser,
    DeleteUser,
    ContextMenu,
    ContextMenuItem
  },
  props: {
    users: {
      type: Array,
      required: true
    }
  },
  created: function() {
    this.$store.dispatch('user/getBuildingUnits');
  },
  methods: {
    editUser: function() {
      const element = this.$refs.modal_edit.$el;
      $(element).modal('show');
    },
    deleteUser: function() {
      const element = this.$refs.modal_delete.$el;
      $(element).modal('show');
    }
  }
};
</script>
