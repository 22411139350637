<template>
  <div
    id="duplicateAndEditContract"
    class="modal fade"
    role="dialog"
    aria-modal="true"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <form id="duplicate-and-edit-contract" @submit.prevent="submitForm">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ $t("contract.duplicate_and_edit") }} <strong>{{ contract.id }}</strong>
            </h5>
          </div>
          <div class="modal-body">
            <div class="form-input">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <FieldConfirm
                    class="w-100"
                    :label-text="$t('contract.unit_number')"
                    :col-label="'col-lg-3'"
                    :col-input="'col-lg-9'"
                    :value="contract.unit_number"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <InputSelect2
                    v-model:selected="holder_id"
                    :input-id="'clone_contract_holder_id'"
                    :place-holder="$t('contract.select_holder')"
                    :label-text="$t('contract.holder_name')"
                    type-select="single_select"
                    :options="collectionUsers"
                    :selected="holder_id"
                    :e-msg="errorMessages['holder_id'] ? errorMessages['holder_id'] : ''"
                    :col-label="'col-lg-3'"
                    :col-input="'col-lg-9'"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <InputNumberCurrency
                    v-model:value-input="building_unit_price"
                    :input-id="'clone_contract_building_unit_price'"
                    :label-text="$t('contract.building_unit_price')"
                    :e-msg="errorMessages['building_unit_price'] ? errorMessages['building_unit_price'] : ''"
                    :col-label="'col-lg-3'"
                    :col-input="'col-lg-9'"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <InputNumberCurrency
                    v-model:value-input="deposited_money"
                    :input-id="'clone_contract_deposited_money'"
                    :label-text="$t('contract.deposited_money')"
                    :e-msg="errorMessages['deposited_money'] ? errorMessages['deposited_money'] : ''"
                    :col-label="'col-lg-3'"
                    :col-input="'col-lg-9'"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <InputDate
                    v-model:value-input="started_date"
                    :input-id="'dp-input-clone_contract_started_date'"
                    :label-text="$t('contract.start_date')"
                    :language="$i18n.locale"
                    :e-msg="errorMessages['started_date'] ? errorMessages['started_date'] : ''"
                    :min-date="new Date()"
                    :name="'clone_contract_started_date'"
                    :col-label="'col-lg-3'"
                    :col-input="'col-lg-9'"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <InputDate
                    v-model:value-input="ended_date"
                    :input-id="'dp-input-clone_contract_ended_date'"
                    :label-text="$t('contract.end_date')"
                    :language="$i18n.locale"
                    :e-msg="errorMessages['ended_date'] ? errorMessages['ended_date'] : ''"
                    :min-date="new Date()"
                    :name="'clone_contract_ended_date'"
                    :col-label="'col-lg-3'"
                    :col-input="'col-lg-9'"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              {{ $t("contract.close") }}
            </button>
            <button type="submit" class="btn btn-danger">
              {{ $t("contract.submit") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery"
import { createNamespacedHelpers } from "vuex"
import { show_flash_mixins } from "../mixins"
const { mapState, mapActions } = createNamespacedHelpers("contract")

export default {
  mixins: [show_flash_mixins],
  data: function () {
    const started_date = new Date()
    const ended_date = new Date(started_date)
    ended_date.setMonth(started_date.getMonth() + 6)
    return {
      holder_id: "",
      building_unit_price: 0,
      started_date,
      ended_date,
      deposited_money: 0,
      contract: {},
    }
  },
  computed: {
    ...mapState({
      currentContract: (state) => state.detailContract,
      collectionUsers: (state) => state.users,
      errorMessages: (state) => state.errorMessages,
      isValid: (state) => state.isValid,
    }),
  },
  watch: {
    currentContract: function (val) {
      this.$store.commit("contract/setErrors", {})
      this.contract = val.contract
      this.holder_id = `${val.contract.holder_id}`
      this.building_unit_price = val.contract.building_unit_price
      this.deposited_money = val.contract.deposited_money
    },
    started_date: function (val) {
      const SIX_MONTH = 6
      const target = new Date(val)
      target.setMonth(target.getMonth() + SIX_MONTH)
      this.ended_date = target
    },
  },
  mounted() {
    const self = this
    $("#duplicateAndEditContract").on("hidden.bs.modal", function (_event) {
      self.onClose()
    })
  },
  methods: {
    ...mapActions(["cloneAndEditContract"]),
    onClose: function () {},
    submitForm: async function () {
      const s_date = new Date(this.started_date)
      const e_date = new Date(this.ended_date)
      const params = {
        id: this.contract.id,
        holder_id: this.holder_id,
        building_unit_id: this.contract.building_unit_id,
        building_unit_price: numeral(this.building_unit_price).value(),
        deposited_money: numeral(this.deposited_money).value(),
        started_date: `${s_date.getDate()}/${s_date.getMonth() + 1}/${s_date.getFullYear()}`,
        ended_date: `${e_date.getDate()}/${e_date.getMonth() + 1}/${e_date.getFullYear()}`,
      }

      await this.cloneAndEditContract({ params: params })

      if (this.isValid) {
        this.show_flash(this.isValid)
        $("#duplicateAndEditContract").modal("hide")
      } else {
        this.show_flash(this.isValid)
      }
    },
  },
}
</script>
