<template>
  <div
    v-if="visible"
    id="flash"
    class="fadeIn d-flex justify-content-start mt-2 mb-2"
  >
    <div
      :class="['alert alert-dismissible fade show w-100',
               type === 'success' ? 'alert-success' : 'alert-danger']"
      @click="hideMessage"
    >
      <button
        class="close"
        data-dismiss="alert"
        type="button"
        @click="hideMessage"
      >
        ×
      </button>
      {{ message }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    position: {
      type: String,
      default: 'right top'
    }
  },
  data() {
    return {
      message: '',
      type: 'success',
      visible: false
    };
  },
  mounted() {
    // Listen for flash message events
    window.addEventListener('flash-message', this.handleFlashMessage);
  },
  beforeUnmount() {
    // Clean up event listener
    window.removeEventListener('flash-message', this.handleFlashMessage);
  },
  methods: {
    handleFlashMessage(event) {
      this.message = event.detail.message;
      this.type = event.detail.type;
      this.visible = true;
    },
    hideMessage() {
      this.visible = false;
      this.$emit('onCloseFlash');
    }
  }
};
</script>
