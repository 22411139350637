/**
 * Utility functions for handling language and localization
 */

/**
 * Gets the current language from the HTML tag
 * @returns {string} The language code (e.g., 'en', 'fr', 'ja')
 */
export function getCurrentLang() {
  return document.documentElement.lang || 'vi';
}

/**
 * Sets the language attribute on the HTML tag
 * @param {string} lang - The language code to set (e.g., 'en', 'fr', 'ja')
 */
export function setHtmlLang(lang) {
  document.documentElement.lang = lang;
  console.log('Set HTML lang attribute to:', lang);
  
  // You might want to trigger a custom event to notify other parts of your application
  // that the language has changed
  const event = new CustomEvent('language-changed', { detail: { lang } });
  window.dispatchEvent(event);
  
  return lang;
} 
