// Flash Message Plugin for Vue 3
import { ref, reactive } from 'vue';

export default {
  install(app) {
    // Create a reactive state for flash messages
    const state = reactive({
      message: '',
      type: 'success',
      time: 3000,
      visible: false
    });

    // Create methods for the flash message
    const methods = {
      show(options) {
        state.message = options.message || '';
        state.type = options.type || 'success';
        state.time = options.time || 3000;
        state.visible = true;

        // Auto-hide after specified time
        if (state.time > 0) {
          setTimeout(() => {
            state.visible = false;
          }, state.time);
        }

        // Emit a custom event that the FlashMessage component can listen to
        const event = new CustomEvent('flash-message', { 
          detail: { 
            message: state.message, 
            type: state.type 
          } 
        });
        window.dispatchEvent(event);
      },
      hide() {
        state.visible = false;
      }
    };

    // Add the $flashMessage property to the global properties
    app.config.globalProperties.$flashMessage = methods;
  }
}; 
