<template>
  <div class="form-inline">
    <div class="form-group">
      <label class="col-form-label mr-2">{{ $t("per_page.show") }}</label>
      <select v-model="perPageVal" class="form-control">
        <option v-for="option in options" :key="option" :value="option">
          {{ option }}
        </option>
      </select>
      <label class="col-form-label ml-2">{{ $t("per_page.items") }}</label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    perPage: {
      type: Number,
      default: 10,
    },
    options: {
      type: Array,
      default: () => [10, 20, 30],
    },
  },
  computed: {
    perPageVal: {
      get() {
        return this.perPage
      },
      set(value) {
        this.$emit("update:per-page", value)
      },
    },
  },
}
</script>
